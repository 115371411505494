import { endpoint } from './endpoint';

export const TRANSLATIONS_PUT = async (
  text: string,
  translation: any,
  plurals?: [
    {
      id: number;
      plural: boolean;
      translatedText: string;
      translationType: string;
      type: string;
      custom: string;
      previous_value: string;
    },
    {
      id: number;
      plural: boolean;
      translatedText: string;
      translationType: string;
      type: string;
      custom: string;
      previous_value: string;
    },
    {
      id: number;
      plural: boolean;
      translatedText: string;
      translationType: string;
      type: string;
      custom: string;
      previous_value: string;
    },
    {
      id: number;
      plural: boolean;
      translatedText: string;
      translationType: string;
      type: string;
      custom: string;
      previous_value: string;
    },
    {
      id: number;
      plural: boolean;
      translatedText: string;
      translationType: string;
      type: string;
      custom: string;
      previous_value: string;
    },
    {
      id: number;
      plural: boolean;
      translatedText: string;
      translationType: string;
      type: string;
      custom: string;
      previous_value: string;
    }
  ]
) => {
  let pluralItems: any = [];
  if (plurals !== undefined) {
    for (let item of plurals) {
      if (item.plural) {
        let data = {
          id: item.id,
          type: item.type,
          value:
            item.translationType === 'translatedText' || item.translationType === 'none'
              ? item.translatedText
              : item.translationType === 'previous_value'
              ? item.previous_value
              : item.custom,
        };
        pluralItems.push(data);
      }
    }
  }

  let putData;

  if (text) {
    putData = { value: text };
  } else {
    putData = { plurals: pluralItems };
  }

  const response = await fetch(`${endpoint}/translations/${translation ? translation.id : -1}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(putData),
  });

  if (!response.ok) {
    const { message } = await response.json();
    throw new Error(message);
  }

  return await response.json();
};
