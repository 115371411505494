import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import ic_back from '../../assets/images/ic_back.png';

type button = {
  confirm?: boolean;
  back?: boolean;
  select?: boolean;
  explain?: boolean;
};

type textarea = {
  plural?: boolean;
  admin?: boolean;
  auto?: number;
};

type image = {
  image?: string;
  modify?: boolean;
};

export const DetailPageContainer = styled.div`
  overflow-y: scroll;
  width: 100vw;
  min-height: 100vh;
`;

export const DataContainer = styled.div`
  position: relative;
  flex-direction: column;
  width: 70%;
  min-height: 100vh;
`;

export const TextBox = styled.div`
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  min-height: 50%;
  margin-top: 2rem;
`;

export const TextExplain = styled.p<button>`
  visibility: ${(props) => (props.explain ? 'visible' : 'hidden')};
  font-weight: bold;
`;

export const Value = styled.p`
  width: 100%;
  font-size: 1.2rem;
  word-wrap: break-word;
`;

export const Line = styled.div`
  margin: 1.5rem auto;
  width: 100%;
  height: 0.2rem;
  background: rgb(164, 204, 241);
  background: linear-gradient(39deg, rgba(164, 204, 241, 1) 0%, rgba(251, 185, 171, 1) 100%);
`;

export const Desc = styled.p`
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #666666;
  word-wrap: break-word;
`;

export const PluralDataBox = styled.div<textarea>`
  flex-direction: row;
  justify-content: flex-start;
  display: ${(props) => (props.plural ? 'flex' : 'none')};
`;

export const PluralType = styled.p`
  margin: 0;
  font-weight: bold;
`;

export const OriginalTextTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TranlsationTextAreaBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
`;

export const TextArea = styled.textarea<textarea>`
  margin: 0.5rem 0 1rem 0;
  padding: 0.4rem;
  width: 100%;
  height: ${(props) => props.auto}em;
  resize: vertical;
  outline: none;
  border: solid 2px gray;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;

  ::placeholder {
    font-size: 1rem;
  }

  :read-only {
    background-color: rgba(0, 0, 0, 0.06);
  }
  ::-webkit-scrollbar {
    width: 0.5rem;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }
`;

export const TypeSwitchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0.5rem;
`;

export const TypeButton = styled.button`
  margin-right: 0.3rem;
  border-radius: 0px;

  :hover {
    background-color: #c1d3e3;
  }
`;

export const RadioBox = styled.div`
  flex-direction: row;
  margin-right: 0.4rem;
  width: 13rem;
  justify-content: flex-end;
  z-index: -1;
`;

export const TranslationTypeSwitch = styled.button<button>`
  margin: 0.2rem 0rem;
  background-color: ${(props) => (props.select ? '#6e93b5' : '')};
`;

export const PluralTranslate = styled.textarea<textarea>`
  display: ${(props) => (props.plural ? 'flex' : 'none')};
  margin: 0.5rem 0 1rem 0;
  padding: 0.4rem;
  width: 100%;
  height: 5.5rem;
  resize: vertical;
  outline: none;
  border: solid 2px gray;
  border-radius: 4px;
  line-height: 1.5rem;
  font-size: 1rem;
  overflow: visible;
  box-sizing: border-box;
`;

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  height: 5rem;
  margin-bottom: 2rem;
`;

export const MoveButton = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4rem;
  margin: 0.4rem;
  border-radius: 5px;
  background-color: #bad1e6;
  text-decoration: none;
  color: black;
`;

export const Button = styled.button<button>`
  background: ${(props) => (props.confirm ? '#FBB9AB' : '')};
  height: 4rem;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const SizedBox = styled.div`
  margin: 0.4rem;
  width: 4.5rem;
  height: 80%;
`;

export const Back = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  background: url(${ic_back}) no-repeat center center;
`;

export const TranslateInformation = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  font-size: 1.5rem;
  background-color: #ffdfda;
`;

export const DropZone = styled.div`
  width: 200px;
  height: 50px;
  background-color: #d4e4f2;
  border: 2px solid #bad1e6;
  cursor: pointer;
  box-sizing: border-box;

  :hover {
    background-color: #bad1e6;
  }
`;

export const ImageBox = styled.div`
  flex-direction: column;
  width: 90%;
`;

export const ImageSet = styled.div`
  display: flex;
  height: 100%;
  width: 90%;
  position: relative;
`;

export const Thumbnail = styled.div<image>`
  margin-bottom: 0.5rem;
  min-width: 6rem;
  min-height: 6rem;
  background: url(${(props) => props.image}) no-repeat center center;
  background-size: contain;
  background-color: #ffece8;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Gallery = styled.div`
  flex-direction: column;
  position: absolute;
  top: 0rem;
  right: -5rem;
  width: 14rem;
  height: 80%;
  justify-content: flex-start;
  margin: 2rem 0;
  overflow-y: scroll;
  box-sizing: border-box;
`;

export const EmptyImage = styled.div`
  justify-content: flex-start;
  margin: 2rem 0;
  width: 90%;
  min-height: 14rem;
  box-sizing: border-box;
`;

export const ImagePopupBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40rem;
  min-height: 40rem;
`;

export const PopupImage = styled.div<image>`
  width: 35rem;
  height: 35rem;
  background: url(${(props) => props.image}) no-repeat center center;
  background-size: contain;
  top: 2rem;
  /* background-color: #fa8191; */
`;

export const PopupDescBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  margin-top: 2rem;
`;

export const PopupDesc = styled.textarea<textarea>`
  padding: 0.5rem;
  width: 100%;
  height: 100%;
  resize: none;
  font-size: 1.2rem;
  outline: none;
  border-radius: 4px;

  :read-only {
    background-color: #ededed;
  }
`;

export const DescImageButton = styled.button`
  height: 100%;
  margin-right: 0.5rem;
  padding: 0.5rem;
  box-sizing: content-box;
`;

export const DeleteImageButton = styled.button`
  height: 100%;
  margin-right: 0.5rem;
  padding: 0.5rem;
  box-sizing: content-box;
  background-color: #fbb9ab;
`;

export const LeftImageArrow = styled.div`
  position: absolute;
  background-color: black;
  top: 50%;
  left: 0;
  width: 5rem;
  height: 5rem;
  margin-top: -2.5rem;
  margin-left: 1rem;
  clip-path: polygon(50% 0, 28% 50%, 50% 100%, 25% 100%, 0% 50%, 25% 0%);
  cursor: pointer;
`;

export const RightImageArrow = styled.div`
  position: absolute;
  background-color: black;
  top: 50%;
  right: 0;
  width: 5rem;
  height: 5rem;
  margin-top: -2.5rem;
  margin-right: 1rem;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 50% 99%, 75% 50%, 50% 0);
  cursor: pointer;
`;

export const PopupCloseButton = styled.button`
  position: absolute;
  background-color: black;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  /* margin-top: 1rem;
  margin-right: 1rem; */
  clip-path: polygon(
    20% 0%,
    0% 20%,
    30% 50%,
    0% 80%,
    20% 100%,
    50% 70%,
    80% 100%,
    100% 80%,
    70% 50%,
    100% 20%,
    80% 0%,
    50% 30%
  );
`;

export const EscText = styled.div`
  position: absolute;
  top: 3rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem;
`;

export const AdminImage = styled.div`
  display: flex;
  width: 90%;
  height: 10rem;
  justify-content: space-around;
`;

export const ImageModifyInput = styled.input<image>`
  display: ${(props) => (props.modify ? 'inline' : 'none')};
`;

export const ImageModifyButton = styled.button<image>`
  display: ${(props) => (props.modify ? 'inline' : 'none')};
`;
