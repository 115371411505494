import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { checkAdmin } from '../../../containers/ExpressionContainer';
import { Link } from 'react-router-dom';
import { PROGRESS_GET } from '../../../lib/api/get_progress_api';
import { CHANGE_LANGUAGE_PUT } from '../../../lib/api/auth_api';
import * as S from './style';
import * as P from '../../main/style';
import { Back } from '../../details/style';
import { Language } from '../../../models/language';

interface TraslationProgress {
  translated: number;
  updated: number;
  total: number;
  translated_percentage: number;
  percentage: number;
}

type LanguageWithProgress = Language & { progress: TraslationProgress };

const ENGLISH = {
  "id": 2,
  "code": "en",
  "name": "English",
  "native_name": "English",
  "emoji": "🇺🇸",
  "plural_types": [
    "one",
    "other"
  ]
} as Language;

const Progress = () => {
  const [languages, setLanguages] = useState([] as LanguageWithProgress[]);
  const [englishPrgress, setEnglishProgress] = useState({ percentage: 0, confirmed: 0, total: 0 });
  const history = useHistory();
  useEffect(() => {
    const isAdmin = checkAdmin(history);
    if (!isAdmin) return;

    PROGRESS_GET()
      .then((res) => {
        setLanguages(res.languages);
        setEnglishProgress(res.expression);
      })
      .catch((err) => alert(err.message));
  }, [history]);

  const getUserLanguage = () => {
    const userData = localStorage.getItem('userId');
    if (userData) {
      const user = JSON.parse(userData) as { language: Language; }
      return user.language;
    }
  };
  const userLanguage = getUserLanguage();
  const changeLanguage = (language: Language) => {
    if (!window.confirm(`Do you want to change language to ${language.name} ${language.emoji}?`)) return;

    CHANGE_LANGUAGE_PUT(language.id)
      .then((res) => {
        localStorage.setItem('userId', JSON.stringify(res));
        localStorage.setItem('resentPage', JSON.stringify(0));
        history.push('/');
      })
      .catch((err) => {
        alert(`Failed to change language: ${err.message}`);
      });
  };

  return (
    <S.ProgressContainer>
      <Link to="/">
        <Back />
      </Link>
      <S.AddLanguageButton
        onClick={() => history.push('/admin/language')}
      >
        Add Language
      </S.AddLanguageButton>
      <S.CountryGrid>
        {englishPrgress.total !== 0 ?
          <S.ProgressInfoBox onClick={userLanguage?.id !== ENGLISH.id ? () => changeLanguage(ENGLISH) : undefined}>
            <S.LanguageBox>
              <S.LanguageText>English</S.LanguageText>
              <S.LanguageText>🇺🇸</S.LanguageText>
            </S.LanguageBox>
            <P.Divide />
            <p style={{ fontSize: '1.1rem' }}>
              {englishPrgress.confirmed} / {englishPrgress.total}
            </p>
            <p style={{ fontSize: '1.1rem' }}>{(englishPrgress.percentage).toFixed(1)}% confirmed.</p>
            <P.ProgressBar>
              <P.Progress progress={englishPrgress.percentage} />
              <P.EmptyBar />
            </P.ProgressBar>
          </S.ProgressInfoBox> : null
        }
        {languages.map(
          (lanaguage) => (
            <S.ProgressInfoBox key={lanaguage.name} onClick={userLanguage?.id !== lanaguage.id ? () => changeLanguage(lanaguage) : undefined}>
              <S.LanguageBox>
                <S.LanguageText>{lanaguage.name}</S.LanguageText>
                <S.LanguageText>{lanaguage.emoji}</S.LanguageText>
              </S.LanguageBox>
              <P.Divide />
              {
                lanaguage.progress.updated !== 0
                  ? <p style={{ fontSize: '1.1rem' }}>
                    {lanaguage.progress.translated + lanaguage.progress.updated}<P.DescText>(-{lanaguage.progress.updated})</P.DescText> / {lanaguage.progress.total}
                  </p>
                  : <p style={{ fontSize: '1.1rem' }}>
                    {lanaguage.progress.translated} / {lanaguage.progress.total}
                  </p>
              }
              <p style={{ fontSize: '1.1rem' }}>
                {(lanaguage.progress.translated_percentage).toFixed(1)}% translated.
                {
                  lanaguage.progress.updated !== 0
                    ? <P.DescText> (but {(lanaguage.progress.translated_percentage - lanaguage.progress.percentage).toFixed(1)}% need to update.)</P.DescText>
                    : null
                }
              </p>
              <P.ProgressBar>
                <P.Progress progress={lanaguage.progress.percentage} />
                <P.Progress progress={lanaguage.progress.translated_percentage} sub={true} />
                <P.EmptyBar />
              </P.ProgressBar>
            </S.ProgressInfoBox>
          )
        )}
      </S.CountryGrid>
    </S.ProgressContainer>
  );
};

export default Progress;
