import { endpoint } from './endpoint';

export const EXPRESSIONS_GET = async (
  id: number,
  offset: number,
  limit: number,
  untranslated: boolean,
  updated: boolean,
  deleted: boolean,
  plurals: boolean,
  noImages: boolean,
  search: string,
  searchByKey: boolean,
) => {
  if (id === 2) {
    return await ADMIN_EXPRESSIONS_GET(offset, limit, untranslated, updated, deleted, plurals, noImages, search, searchByKey);
  }

  const limitNumber = limit ? limit : 15;

  const include = !untranslated;

  const response = await fetch(
    `${endpoint}/expressions?language_id=${id}&offset=${offset}&limit=${limitNumber}&untranslated_only=${include}&updated_only=${updated}&plurals_only=${plurals}&no_images_only=${noImages}&search=${search}&search_by_key=${searchByKey}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `${localStorage.getItem('accessToken')}`,
      },
    }
  );

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};

export const ADMIN_EXPRESSIONS_GET = async (
  offset: number,
  limit: number,
  untranslated: boolean,
  updated: boolean,
  deleted: boolean,
  plurals: boolean,
  noImages: boolean,
  search: string,
  searchByKey: boolean,
) => {
  const limitNumber = limit ? limit : 15;

  const include = !untranslated;

  const response = await fetch(
    `${endpoint}/expressions?offset=${offset}&limit=${limitNumber}&not_confirmed_only=${include}&updated_only=${updated}&deleted_only=${deleted}&plurals_only=${plurals}&no_images_only=${noImages}&search=${search}&search_by_key=${searchByKey}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `${localStorage.getItem('accessToken')}`,
      },
    }
  );

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};
