import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  @media only screen and (min-width: 1920px) {
    html {
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 1920px) {
    html {
      font-size: 16px;
    };
  }
  
  html, body {
    margin: 0;
    padding: 0;
    width: 100vw;
    overflow-x: hidden;
    font-size: 16px;
    font-family: 'Pretendard-Regular';
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    margin: 0;
  }

  button {
    padding: 0.5rem;
    background-color: #D4E4F2;
    border: none;
    outline: none;
    border-radius: 3px;
    cursor: pointer;
  }

  @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
`;
