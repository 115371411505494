import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import main_background from '../../assets/images/main_background.jpg';

type admin = {
  admin: boolean;
};

type button = {
  enabled: boolean;
};

type image = {
  image?: string;
  modify?: boolean;
};

type progressBar = {
  progress: number;
  sub?: boolean;
};

export const Background = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url(${main_background}) no-repeat center center;
  background-size: cover;
`;

export const UserName = styled.p`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const UserEmail = styled.p`
  position: absolute;
  top: 2.5rem;
  right: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const ChangePassword = styled.button`
  position: absolute;
  top: 4.5rem;
  right: 5rem;
  font-size: 0.8rem;
  z-index: 50;
`;

export const Logout = styled.button`
  position: absolute;
  top: 4.5rem;
  right: 1rem;
  font-size: 0.8rem;
  z-index: 50;
`;

export const AddExpression = styled.button<admin>`
  display: ${(props) => (props.admin ? 'flex' : 'none')};
  position: absolute;
  top: 7rem;
  right: 1rem;
  background: #fbb9ab;
  font-size: 0.8rem;
  z-index: 50;
`;

export const Header = styled.div`
  height: 5.2rem;
  width: 90%;
  justify-content: flex-start;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export const StyledLink = styled(Link)<button>`
  text-decoration: none;
  pointer-events: ${(props) => (props.enabled ? 'auto' : 'none')};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: black;
  }
`;

export const ProgressInfoBox = styled.div<admin>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 18rem;
  min-height: 100%;
  padding: 0.8rem;
  background-color: #fcfcfc;
  border: 1px solid rgba(255, 186, 171, 1);
  border-radius: 3px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  :hover {
    background-color: ${(props) => (props.admin ? 'rgba(255, 186, 171, 0.2)' : '')};
  }
`;

export const LanguageBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const UserNativeLanguage = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff866b;
`;

export const UserLanguage = styled.p`
  font-size: 0.9rem;
`;

export const Divide = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.05);
`;

export const ProgressBar = styled.div`
  position: relative;
  margin-top: 0.2rem;
  width: 100%;
`;

export const EmptyBar = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.5rem;
  background-color: rgba(255, 186, 171, 0.2);
  z-index: 100;
  border-radius: 2px;
`;

export const Progress = styled.div<progressBar>`
  position: absolute;
  left: 0;
  width: ${(props) => props.progress}%;
  height: 0.5rem;
  background-color: ${(props) => (props.sub ? '#ff866b80' : '#ff866b')};
  border-radius: 2px;
`;

export const FilterBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 35rem;
  height: 120%;
  padding: 0.8rem;
`;

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

export const InputBox = styled.div`
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  height: 70%;
  border-bottom: solid 3px #fbb9ab;
`;

export const SearchIcon = styled.img`
  width: 2rem;
  margin-left: -0.2rem;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background: none;
  ::placeholder {
    color: gray;
  }
`;

export const SearchNote = styled.p`
  font-style: italic;
`;

export const ButtonBox = styled.div`
  justify-content: flex-start;
  height: 100%;
`;

export const ShowType = styled.div`
  height: 100%;
  padding: 0.5rem;
  margin-right: 0.2rem;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const Show = styled.button`
  :hover {
    background-color: #bad1e6;
  }
`;

export const ListContainer = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const TextTypeBox = styled.div<admin>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.admin ? '0.4fr 0.2fr 3fr' : '3fr 0.2fr 3fr'};
  align-items: center;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.5rem;
  border-bottom: solid 1px #fbb9ab;
`;

export const ListBox = styled.div`
  position: relative;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 90%;
  height: 80%;
`;

export const TextBox = styled(Link)<admin>`
  position: relative;
  display: grid;
  grid-template-columns: ${(props) =>
    props.admin ? '0.4fr 3fr 0.1fr' : '3fr 0.3fr 3fr'};
  align-items: center;
  width: 100%;
  height: 6%;
  text-decoration: none;
  color: black;
  cursor: pointer;
  border-bottom: solid 1px #fbb9ab;
  overflow-x: visible;

  :hover {
    background-color: #f7e9e4;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: 50%;
  left: -3rem;
  width: 2rem;
  height: 2rem;
  margin-top: -1rem;
  color: red;
  font-weight: bold;
  font-size: 0.8rem;
`;

export const OriginalTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DescText = styled.span`
  width: 100%;
  font-size: 0.8rem;
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Text = styled.p`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export const VerticalLine = styled.div`
  height: 100%;
  width: 1px;
  background: #fbb9ab;
`;

export const Image = styled.div<image>`
  width: 100%;
  min-height: 80%;
  background: url(${(props) => props.image}) no-repeat center center;
  background-size: contain;
  cursor: pointer;
`;

const spin = keyframes`
  0%{
    transform: rotate(0deg);
  }
  50%{
    clip-path: polygon(50% 50%, -50% 100%, 110% 100%);
  }
  90%{
    clip-path: polygon(50% 50%, 30% 100%, 70% 100%);  
  }
  100%{
    transform: rotate(1080deg);
  }
`;

export const CircularLoading = styled.div`
  position: absolute;
  width: 15rem;
  height: 15rem;
  top: 50%;
  left: 50%;
  margin-top: -7.5rem;
  margin-left: -7.5rem;
  border-radius: 50%;
  clip-path: polygon(50% 50%, 30% 100%, 70% 100%);
  background-color: #a3a3a3;
  animation: ${spin} 2s infinite ease-in-out;

  ::after {
    position: absolute;
    content: '';
    background-color: #ffffff;
    width: 13.5rem;
    height: 13.5rem;
    border-radius: 50%;
  }
`;

export const Wait = styled.div`
  position: absolute;
  margin: 0;
  top: 50%;
  margin-top: -1.25rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: 2.5rem;
  font-weight: bold;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: start;
  bottom: 0;
  width: 12rem;
  height: 5%;
  margin-top: 0.5rem;
`;

export const NumberButton = styled.button`
  margin: 0 0.1rem;
  width: 2rem;
  height: 2rem;
  background-color: black;
  color: white;
`;

export const PaginationNumber = styled.div`
  width: 5rem;
`;

export const PaginationButton = styled.button<button>`
  margin: 0 0.5rem;
  visibility: ${(props) => (!props.enabled ? 'hidden' : '')};
`;

export const PluralIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
`;

export const EmptyBox = styled.div``;
