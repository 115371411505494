import React, { useEffect } from 'react';
import { useState } from 'react';
import { LANGUAGES_GET } from '../../../lib/api/auth_api';
import { MainContainer } from '../../../assets/styles/commonStyle';
import * as S from '../authStyle';
import { signUpPost } from '../../../containers/AuthContainer';

const Signup = () => {
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    password: '',
    checkPassword: '',
  });
  const [nameError, setNameError] = useState(true);
  const [emailError, setEmailError] = useState(true);
  const [passwordError, setPasswordError] = useState(true);
  const [passwordCheckError, setPasswordCheckError] = useState(true);
  const [lanId, setLanId] = useState(0);
  const [languages, setLanguages] = useState<any>([]);

  useEffect(() => {
    LANGUAGES_GET()
      .then((res) => {
        setLanguages(res.items);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  const languageSelect = (e: any) => {
    setLanId(Number(e.target.value));
  };

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const enter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      signUpPost(
        inputs,
        lanId,
        setNameError,
        setEmailError,
        setPasswordError,
        setPasswordCheckError
      );
    }
  };

  return (
    <MainContainer>
      <S.Form>
        <S.Center>
          <S.TitleBox>
            <S.Title>Welcome to&nbsp;</S.Title>
            <S.I18N>Aurender Translator</S.I18N>
          </S.TitleBox>
          <S.InputWrapper>
            <S.Input
              name="name"
              type="name"
              placeholder="Name"
              value={inputs.name}
              onChange={onChange}
              onKeyPress={enter}
            />
            <S.ErrorText succes={nameError}>check your name.</S.ErrorText>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              name="email"
              type="email"
              placeholder="Email"
              value={inputs.email}
              onChange={onChange}
              onKeyPress={enter}
            />
            <S.ErrorText succes={emailError}>check your email.</S.ErrorText>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              name="password"
              type="password"
              placeholder="Password"
              value={inputs.password}
              onChange={onChange}
              onKeyPress={enter}
            />
            <S.ErrorText succes={passwordError}>check your passwords.</S.ErrorText>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              name="checkPassword"
              type="password"
              placeholder="Confirm Password"
              value={inputs.checkPassword}
              onChange={onChange}
              onKeyPress={enter}
            />
            <S.ErrorText succes={passwordCheckError}>passwords do not match.</S.ErrorText>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Select id="lan" name="lan" onChange={languageSelect}>
              <option value="0">language</option>
              {languages ? (
                languages.map((e: any) => (
                  <option key={e.id} value={e.id}>
                    {e.native_name}
                  </option>
                ))
              ) : (
                <option value="wait">language&ensp;&nbsp;&nbsp;</option>
              )}
            </S.Select>
          </S.InputWrapper>
          <S.Button
            onClick={() =>
              signUpPost(
                inputs,
                lanId,
                setNameError,
                setEmailError,
                setPasswordError,
                setPasswordCheckError
              )
            }
          >
            Sign up
          </S.Button>
        </S.Center>
      </S.Form>
    </MainContainer>
  );
};

export default Signup;
