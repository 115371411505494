import { useState } from 'react';
import { withRouter } from 'react-router';
import { MainContainer } from '../../../assets/styles/commonStyle';
import * as S from '../authStyle';
import { signInPost } from '../../../containers/AuthContainer';

const Signin = () => {
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });
  const [emailError, setEmailError] = useState(true);
  const [passwordError, setPasswordError] = useState(true);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const enter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      signInPost(inputs, setEmailError, setPasswordError);
    }
  };

  return (
    <MainContainer>
      <S.Form>
        <S.Center>
          <S.Title>Sign in to your account</S.Title>
          <S.InputWrapper>
            <S.Input
              name="email"
              type="email"
              placeholder="Email"
              value={inputs.email}
              onChange={onChange}
              onKeyPress={enter}
            />
            <S.ErrorText succes={emailError}>check your email.</S.ErrorText>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              name="password"
              type="password"
              placeholder="Password"
              value={inputs.password}
              onChange={onChange}
              onKeyPress={enter}
            />
            <S.ErrorText succes={passwordError}>check your passwords.</S.ErrorText>
          </S.InputWrapper>
          <S.Button onClick={() => signInPost(inputs, setEmailError, setPasswordError)}>
            Sign in
          </S.Button>
        </S.Center>
      </S.Form>
      <S.HaveAccount href="/signup">Don't have an account yet?</S.HaveAccount>
    </MainContainer>
  );
};

export default withRouter(Signin);
