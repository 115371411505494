import { useHistory, withRouter } from 'react-router';
import { MainContainer } from '../../../assets/styles/commonStyle';
import * as S from '../authStyle';
import { changePasswordPut } from '../../../containers/AuthContainer';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ChangePassword = () => {
  const [inputs, setInputs] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [confirmError, setConfirmError] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('userId')) {
      history.push('/signin');
      return;
    }
  }, [history]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const enter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      changePasswordPut(inputs, setConfirmError);
    }
  };
  return (
    <MainContainer>
      <Link to="/">
        <S.Back />
      </Link>
      <S.Form>
        <S.Center>
          <S.Title>Change Password</S.Title>
          <S.InputWrapper>
            <S.Input
              name="newPassword"
              type="password"
              placeholder="New Password"
              value={inputs.newPassword}
              onChange={onChange}
              onKeyPress={enter}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Input
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              value={inputs.confirmPassword}
              onChange={onChange}
              onKeyPress={enter}
            />
            <S.ErrorText succes={confirmError}>Incorrect passwords.</S.ErrorText>
          </S.InputWrapper>
          <S.Button onClick={() => changePasswordPut(inputs, setConfirmError)}>Save</S.Button>
        </S.Center>
      </S.Form>
    </MainContainer>
  );
};

export default withRouter(ChangePassword);
