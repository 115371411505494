import styled from 'styled-components';

export const Box = styled.div`
  display: inline;
  cursor: pointer;
  transition: color 0.2s ease;

  :hover {
    color: #bad1e6;
  }
`;

export const No = styled.p`
  font-size: 5rem;
`;

export const SizedBox = styled.div`
  height: 2rem;
`