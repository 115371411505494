import { useHistory } from 'react-router';
import { MainContainer } from '../../assets/styles/commonStyle';
import * as S from './style';

const NotFound = () => {
  const history = useHistory();
  const back = () => {
    history.push('/');
  }

  return (
    <MainContainer>
      <S.Box onClick={back}>
        <S.No>🤨</S.No>
        <S.SizedBox/>
        <S.No>You have accessed the wrong address.</S.No>
        <S.No>Go back to the main page.</S.No>
      </S.Box>
    </MainContainer>
  );
};

export default NotFound;
