import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Color from './components/color/Color';
import Main from './components/main/Main';
import Signup from './components/auth/signup/Signup';
import Signin from './components/auth/signin/Signin';
import Details from './components/details/Details';
import NotFound from './components/notFound/NotFound';
import Expression from './components/admin/expression/Expression';
import Progress from './components/admin/progress/Progress';
import Language from './components/admin/language/Language';
import ChangePassword from './components/auth/changePassword/ChangePassword';

const Routing = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Main} />
        <Route path="/admin/expression" component={Expression} />
        <Route path="/admin/progress" component={Progress} />
        <Route path="/admin/language" component={Language} />
        <Route path="/details/:id" component={Details} />
        <Route path="/signin" component={Signin} />
        <Route path="/users/password" component={ChangePassword} />
        <Route path="/signup" component={Signup} />
        <Route path="/color" component={Color} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Routing;
