import * as AWS from 'aws-sdk';
import { Body } from 'aws-sdk/clients/s3';
import { v4 as uuidv4 } from 'uuid';

const BUCKET_NAME = 'i18n-app';
const ACCESS_KEY_ID = 'AKIAR5GJ4LUUSIYBF27T';
const SECRET_ACCESS_KEY = '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';

AWS.config.credentials = new AWS.Credentials(ACCESS_KEY_ID, SECRET_ACCESS_KEY);
AWS.config.region = 'us-east-1';

const S3 = new AWS.S3();

export async function uploadImage(
  data: Body,
  contentType = 'image/png',
  extension = 'png'
): Promise<string> {
  const params = {
    Bucket: BUCKET_NAME,
    Key: `images/${uuidv4()}.${extension}`,
    ContentType: contentType,
    Body: data,
  } as AWS.S3.Types.PutObjectRequest;

  const url = await new Promise<string>((resolve, reject) => {
    S3.upload(params, (err, data) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(data.Location);
    });
  });
  return url;
}
