import { EXPRESSIONS_GET } from '../lib/api/get_expression_api';

const LIMIT = 15;

export const pageChange = (
  page: number,
  lanId: number,
  untranslated: boolean,
  updated: boolean,
  deleted: boolean,
  plurals: boolean,
  noImages: boolean,
  searchValue: string,
  searchByKey: boolean,
  setCurrentOffset: Function,
  setTranslate: Function
) => {
  let offset = page * LIMIT;
  setCurrentOffset(offset);
  return EXPRESSIONS_GET(lanId, offset, LIMIT, untranslated, updated, deleted, plurals, noImages, searchValue, searchByKey)
    .then((res) => {
      if (res.total === 0) {
        alert('Last page.');
        return;
      }
      setTranslate(res);
      localStorage.setItem('resentPage', JSON.stringify(page < 0 ? 0 : page));
      let scroll = document.getElementById('scrollElement');
      if (scroll) scroll.scrollTop = 0;
    })
    .catch((err) => alert(err.message));
};

export const fetchTranslateData = (
  native_id: number,
  untranslated: boolean,
  updated: boolean,
  deleted: boolean,
  plurals: boolean,
  noImages: boolean,
  searchValue: string,
  searchByKey: boolean,
  setTranslate: Function,
  setProgress: Function,
  setIsFirst: Function,
  setIsLast: Function,
) => {
  let resentPage = localStorage.getItem('resentPage');

  return EXPRESSIONS_GET(
    native_id,
    resentPage ? Number(resentPage) * LIMIT : 0,
    LIMIT,
    untranslated,
    updated,
    deleted,
    plurals,
    noImages,
    searchValue,
    searchByKey,
  )
    .then((res) => {
      setTranslate(res);
      setProgress(res.progress);

      if (Number(resentPage) === 0) {
        setIsFirst(true);
      }
    })
    .catch((err) => {
      alert(err.message);
    });
};

export const fetchAllTranslateData = (
  native_id: number,
  untranslated: boolean,
  updated: boolean,
  deleted: boolean,
  plurals: boolean,
  noImages: boolean,
  searchValue: string,
  searchByKey: boolean,
) => EXPRESSIONS_GET(native_id, 0, 1000, untranslated, updated, deleted, plurals, noImages, searchValue, searchByKey).then(res => res.items);

export const showTypeSettings = (filterType: string, data: boolean, setData: Function) => {
  setData(!data);
  localStorage.setItem(filterType, JSON.stringify(!data));
  localStorage.setItem('resentPage', JSON.stringify(0));
};

export const logout = (history: any) => {
  localStorage.removeItem('userId');
  localStorage.setItem('resentPage', JSON.stringify(0));
  localStorage.removeItem('resentSearch');
  localStorage.removeItem('untranslated');
  localStorage.removeItem('updated');
  localStorage.removeItem('plurals');
  localStorage.removeItem('deleted');
  localStorage.removeItem('noImages');
  history.push('/signin');
};
