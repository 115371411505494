import { useEffect, useState } from 'react';
import { updateImageUpload } from '../../containers/DetailContainer';
import * as S from './style';

function ImagePopup({ imageDatas, id, editable, setRevisedImage, deleteImage }: any) {
  const [viewImage, setViewImage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);
  const [imageId, setImageId] = useState(id);
  const [descValue, setDescValue] = useState('');

  useEffect(() => {
    let currentIndex = imageDatas.findIndex((e: any) => {
      return e.id === id;
    });

    if (currentIndex === -1) {
      return;
    }
    setDescValue(imageDatas[currentIndex].description);
    setImageId(imageDatas[currentIndex].id);
    setImageIndex(currentIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, imageDatas]);

  useEffect(() => {
    setViewImage(imageDatas[imageIndex].url);
    setImageId(imageDatas[imageIndex].id);
    setDescValue(imageDatas[imageIndex].description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageIndex, imageDatas]);

  const editDescValue = (e: any) => {
    setDescValue(e.target.value);
  };

  const putDescValue = () => {
    updateImageUpload(imageId, descValue, setRevisedImage);
  };

  return (
    <S.ImagePopupBox id="imageBox">
      <S.PopupImage image={viewImage} />
      {(editable || descValue) && (
        <S.PopupDescBox>
          <>
            <S.PopupDesc value={descValue} onChange={editDescValue} readOnly={!editable} />
            {editable && (
              <>
                <S.DescImageButton onClick={putDescValue}>Change the description</S.DescImageButton>
                <S.DeleteImageButton
                  onClick={() => {
                    deleteImage(imageId);
                  }}
                >
                  Delete image
                </S.DeleteImageButton>
              </>
            )}
          </>
        </S.PopupDescBox>
      )}
    </S.ImagePopupBox>
  );
}

export default ImagePopup;
