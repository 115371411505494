import styled from 'styled-components';

type color = {
  a: string;
}

export const Block = styled.div<color>`
  height: 16.6vh;
  width: 100vw;
  background-color: ${props => props.a};
  font-size: 32px;
`

export const CC = styled.p`
  position: absolute;
  font-size: 72px;
  color: white;
  text-shadow: 1px 1px 5px #E7EDF7;
  font-family: 'Pretendard-Regular';
`