import styled from 'styled-components';

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90vw;
  height: 100vh;
`;

export const CountryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 80%;
  place-items: center;
`;

export const ProgressInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  height: 90%;
  padding: 0.8rem;
  box-sizing: border-box;
  background-color: #fcfcfc;
  border: 1px solid rgba(255, 186, 171, 1);
  border-radius: 3px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;

export const LanguageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 2rem;
`;

export const LanguageText = styled.p`
  font-size: 1.25rem;
  color: #ff866b;
  font-weight: bold;
  margin-left: 0.3rem;
`;

export const PluralDataBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AddLanguageButton = styled.button`
  position: absolute;
  top: 1.0rem;
  right: 1.0rem;
  background: #fbb9ab;
  z-index: 50;
`;
