import styled from 'styled-components';

type pluralBox = {
  plural?: boolean;
  auto?: number;
};

type popup = {
  isShow: boolean;
};

export const PluralDataBox = styled.div<pluralBox>`
  flex-direction: row;
  justify-content: flex-start;
  display: ${(props) => (props.plural ? 'flex' : 'none')};
`;

export const PluralType = styled.p`
  margin: 0;
  font-weight: bold;
`;

export const PluralTranslate = styled.textarea<pluralBox>`
  display: ${(props) => (props.plural ? 'flex' : 'none')};
  margin: 0.5rem 0 1rem 0;
  padding: 0.4rem;
  width: 100%;
  height: ${(props) => props.auto}em;
  resize: vertical;
  outline: none;
  border: solid 2px gray;
  border-radius: 4px;
  font-size: 1rem;
  overflow: visible;
  box-sizing: border-box;

  :read-only {
    background-color: rgba(0, 0, 0, 0.06);
  }
`;

export const PluralTypeInfo = styled.p<pluralBox>`
  display: ${(props) => (props.plural ? 'flex' : 'none')};
  margin-top: -1rem;
  margin-bottom: 2rem;
  font-style: italic;
`;

export const TextExplain = styled.p`
  font-weight: bold;
`;

export const InfoBox = styled.div`
  position: relative;
`;

export const InfoIcon = styled.div`
  position: relative;
  margin-left: 0.4rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: gray;
  color: white;
`;

export const Popup = styled.div<popup>`
  display: ${(props) => (props.isShow ? 'inline' : 'none')};
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  width: 30rem;
  max-height: 5rem;
  padding: 0.5rem;
  background-color: #eeeeee;
  border-radius: 5px;
`;
