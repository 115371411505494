/* eslint-disable no-restricted-globals */
export const translationTypeSwitch = (
  e: any,
  datas: {
    type: string;
    translatedText: string;
    translationType: string;
    previous_value: string;
    custom: string;
    isSeleted: boolean;
  },
  translation: any,
  setDatas: Function
) => {
  let type = e.target.value;

  switch (datas.translationType) {
    case 'translatedText':
      setDatas({
        ...datas,
        translatedText: translation.machine_translation,
        translationType: type,
        isSeleted: true,
      });

      return;
    case 'previous_value':
      setDatas({
        ...datas,
        previous_value: translation.previous_value,
        translationType: type,
        isSeleted: true,
      });

      return;
    case 'custom':
      setDatas({
        ...datas,
        custom:
          translation.machine_translation + '\n====================\n' + translation.previous_value,
        translationType: type,
        isSeleted: true,
      });

      return;
    default:
      setDatas({
        ...datas,
        translationType: type,
        isSeleted: true,
      });
  }
};

export const textAreaValueSet = (datas: any, value: string) => {
  if (datas.translationType === 'none' && datas.previous_value && !value) {
    return '';
  } else if (datas.translationType === 'translatedText') {
    return datas.translatedText;
  } else if (datas.translationType === 'previous_value') {
    return datas.previous_value;
  } else if (datas.translationType === 'custom') {
    return datas.custom;
  } else if (datas.translationType === 'none' && !datas.previous_value) {
    return datas.translatedText;
  } else {
    return datas.translatedText;
  }
};

export const textAreaLineSet = (pluralDatas: any, translationType: string) => {
  if (translationType === 'translatedText') {
    return pluralDatas.translatedText.split('\n').length + 2;
  } else if (translationType === 'previous_value') {
    return pluralDatas.previous_value.split('\n').length + 2;
  } else if (translationType === 'custom') {
    return pluralDatas.custom.split('\n').length + 2;
  } else {
    return pluralDatas.translatedText.split('\n').length + 2;
  }
};
