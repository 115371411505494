import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { MainContainer } from '../../assets/styles/commonStyle';
import ic_search from '../../assets/images/ic_search.png';
import translation from '../../assets/images/Google_Translate_logo.svg';
import * as S from './style';
import {
  fetchAllTranslateData,
  fetchTranslateData,
  logout,
  pageChange,
  showTypeSettings,
} from '../../containers/MainContainer';

const Main = () => {
  const getBooleanValue = (key: string) => {
    const value = localStorage.getItem(key);
    if (value === 'true') {
      return true;
    } else if (value === 'false') {
      return false;
    } else {
      localStorage.setItem(key, JSON.stringify(false));
      return false;
    }
  };

  const [translate, setTranslate] = useState({ total: 0, items: [{ id: '', value: '' }] });
  const [isLoading, setLoading] = useState(true);
  const [allTranlateData, setAllTranslateData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [untranslatedOnly, setUntranslatedOnly] = useState(getBooleanValue('untranslated'));
  const [updatedOnly, setUpdatedOnly] = useState(getBooleanValue('updated'));
  const [pluralsOnly, setPluralsOnly] = useState(getBooleanValue('plurals'));
  const [noImagesOnly, setNoImagesOnly] = useState(getBooleanValue('noImages'));
  const [deletedOnly, setDeletedOnly] = useState(getBooleanValue('deleted'));
  const [searchValue, setSearchValue] = useState('');
  const [searchByKey, setSearchByKey] = useState(getBooleanValue('searchByKey'));
  const [checkUpdate, setCheckUpdate] = useState(false);
  const [progress, setProgress] = useState<{
    // english
    confirmed?: number;
    // other
    translated?: number;
    updated?: number;
    translated_percentage?: number;
    // both
    total: number;
    percentage: number;
  }>({ confirmed: 0, translated: 0, total: 0, percentage: 0 });
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [lanId, setLanId] = useState(0);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [realPageNumber, setRealPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isFirst, setIsFirst] = useState(false);
  const [isLast, setIsLast] = useState(false);
  const [searching, setSearching] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem('userId')) {
      history.push('/signin');
      return;
    }

    const resentPage = localStorage.getItem('resentPage');
    const resentSearchValue = localStorage.getItem('resentSearch');

    if (typeof resentPage === 'string') {
      setRealPageNumber(Number(resentPage));
    }
    if (!resentSearchValue) {
      localStorage.setItem('resentSearch', JSON.stringify(''));
    }

    const user = localStorage.getItem('userId');
    if (typeof user === 'string') {
      const userData = JSON.parse(user) as {
        language: { id: number };
        is_admin: boolean;
        email: string;
        name: string;
      };
      let native_id = userData.language.id;
      setLanId(native_id);
      setIsAdmin(userData.is_admin);
      setUserName(userData.name);
      setUserEmail(userData.email);
      let storageSearchValue = '';
      if (typeof resentSearchValue === 'string') {
        storageSearchValue = JSON.parse(resentSearchValue);
        setSearchValue(storageSearchValue);
      }

      setSearching(false);

      setLoading(true);
      fetchTranslateData(
        native_id,
        untranslatedOnly,
        updatedOnly,
        deletedOnly,
        pluralsOnly,
        noImagesOnly,
        storageSearchValue,
        searchByKey,
        setTranslate,
        setProgress,
        setIsFirst,
        setIsLast,
      ).then(() => setLoading(false)); // 현재 페이지 번역 데이터
      fetchAllTranslateData(
        native_id,
        untranslatedOnly,
        updatedOnly,
        deletedOnly,
        pluralsOnly,
        noImagesOnly,
        storageSearchValue,
        searchByKey,
      ).then(items => setAllTranslateData(items)); // 전체 번역 데이터
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, untranslatedOnly, updatedOnly, pluralsOnly, noImagesOnly, deletedOnly, checkUpdate, searchByKey]);

  useEffect(() => {
    const allPage = translate.total !== 0 ? Math.ceil(translate.total / 15) : 1;
    setNumberOfPages(allPage);
    const currentPage = realPageNumber + 1;

    if (currentPage === allPage) {
      setIsLast(true);
    } else {
      setIsLast(false);
    }
  }, [translate, realPageNumber]);

  const handleSearchInput = (e: any) => {
    let text = e.target.value;
    setSearchValue(text);
  };

  const typing: any = useRef(null);

  const checkTyping = () => {
    clearTimeout(typing.current);

    setSearching(true);
    typing.current = setTimeout(() => {
      localStorage.setItem('resentPage', JSON.stringify(0));
      setCheckUpdate(!checkUpdate);
      localStorage.setItem('resentSearch', JSON.stringify(searchValue));
    }, 1500);
  };

  const clearTyping = () => {
    clearTimeout(typing.current);
  };

  const prev = () => {
    let offset = realPageNumber - 1;
    if (offset < 0) {
      alert('First page.');
      return;
    }
    setLoading(true);
    pageChange(
      offset,
      lanId,
      untranslatedOnly,
      updatedOnly,
      deletedOnly,
      pluralsOnly,
      noImagesOnly,
      searchValue,
      searchByKey,
      setCurrentOffset,
      setTranslate
    ).then(() => setLoading(false));

    setRealPageNumber(realPageNumber - 1);
    if (realPageNumber + 2 !== numberOfPages) {
      setIsLast(false);
    }
    if (realPageNumber - 1 === 0) {
      setIsFirst(true);
      return;
    }
  };

  const next = () => {
    let offset = realPageNumber + 1;
    setLoading(true);
    pageChange(
      offset,
      lanId,
      untranslatedOnly,
      updatedOnly,
      deletedOnly,
      pluralsOnly,
      noImagesOnly,
      searchValue,
      searchByKey,
      setCurrentOffset,
      setTranslate
    ).then(() => setLoading(false));

    setRealPageNumber(realPageNumber + 1);
    if (realPageNumber - 1 !== 0) {
      setIsFirst(false);
    }
    if (realPageNumber + 2 === numberOfPages) {
      setIsLast(true);
      return;
    }
  };

  const getUserLanguage = () => {
    const userData = localStorage.getItem('userId');
    if (userData) {
      const user = JSON.parse(userData) as {
        language: { code: string, name: string, native_name: string, emoji: string };
      }
      return user.language;
    }
  };
  const language = getUserLanguage();
  const isEnglish = language?.code === 'en'; // user must be admin (isAdmin = true)

  return (
    <MainContainer>
      <S.UserName>Username : {userName ?? ''}</S.UserName>
      <S.UserEmail>{userEmail}</S.UserEmail>
      <S.Logout
        onClick={() => {
          logout(history);
        }}
      >
        Logout
      </S.Logout>
      <S.ChangePassword onClick={() => history.push('/users/password')}>
        Change Password
      </S.ChangePassword>
      {isAdmin ? (
        <S.AddExpression
          admin={isAdmin}
          onClick={() => history.push('/admin/expression')}
        >
          Create Expression
        </S.AddExpression>
      ) : (
        <div />
      )}
      <S.ListContainer>
        <S.Header>
          <S.StyledLink to="admin/progress" enabled={isAdmin}>
            <S.ProgressInfoBox admin={isAdmin}>
              <S.LanguageBox>
                <S.UserNativeLanguage>{language?.native_name} {language?.emoji}</S.UserNativeLanguage>
                <S.UserLanguage>{language?.name}</S.UserLanguage>
              </S.LanguageBox>
              <S.Divide />
              <p>
                {(() => {
                  if (progress.translated !== undefined && progress.updated !== undefined) {
                    return progress.updated !== 0
                      ? <>{progress.translated + progress.updated}<S.DescText>(-{progress.updated})</S.DescText></>
                      : <>{progress.translated}</>
                  }
                  return progress.confirmed;
                })()} / {progress.total}
              </p>
              <p>
                {(progress.translated_percentage ?? progress.percentage).toFixed(1) + '%'} {isEnglish ? 'confirmed.' : 'translated.'}
                {
                  progress.translated_percentage && progress.updated !== 0
                    ? <S.DescText> (but {(progress.translated_percentage - progress.percentage).toFixed(1)}% need to update.)</S.DescText>
                    : null
                }
              </p>
              <S.ProgressBar>
                <S.Progress progress={progress.percentage} />
                {progress.translated_percentage && (<S.Progress progress={progress.translated_percentage} sub={true} />)}
                <S.EmptyBar />
              </S.ProgressBar>
            </S.ProgressInfoBox>
          </S.StyledLink>
          <S.FilterBox>
            <S.ButtonBox>
              <input
                type="checkbox"
                onChange={() => {
                  showTypeSettings('untranslated', untranslatedOnly, setUntranslatedOnly);
                }}
                checked={untranslatedOnly}
              />
              <p style={{ paddingLeft: '0.5rem' }}>
                {isEnglish ? 'Include Already Confirmed' : 'Include Already Translated'}
              </p>
            </S.ButtonBox>
            <S.ButtonBox>
              {!isEnglish ? <>
                <input
                  type="checkbox"
                  onChange={() => {
                    showTypeSettings('updated', updatedOnly, setUpdatedOnly);
                  }}
                  checked={updatedOnly}
                />
                <p style={{ paddingLeft: '0.5rem' }}>Updated Only</p>
              </> : <>
                <input
                  type="checkbox"
                  onChange={() => {
                    showTypeSettings('plurals', pluralsOnly, setPluralsOnly);
                  }}
                  checked={pluralsOnly}
                />
                <p style={{ paddingLeft: '0.5rem' }}>Plurals Only</p>
                <div style={{ margin: '0.2rem' }}></div>
                <input
                  type="checkbox"
                  onChange={() => {
                    showTypeSettings('noImages', noImagesOnly, setNoImagesOnly);
                  }}
                  checked={noImagesOnly}
                />
                <p style={{ paddingLeft: '0.5rem' }}>No Images Only</p>
                <div style={{ margin: '0.2rem' }}></div>
                <input
                  type="checkbox"
                  onChange={() => {
                    showTypeSettings('deleted', deletedOnly, setDeletedOnly);
                  }}
                  checked={deletedOnly}
                />
                <p style={{ paddingLeft: '0.5rem' }}>Deleted Only</p>
              </>}
              {isAdmin ? <>
                <div style={{ margin: '0.2rem' }}></div>
                <input
                  type="checkbox"
                  onChange={() => {
                    showTypeSettings('searchByKey', searchByKey, setSearchByKey);
                  }}
                  checked={searchByKey}
                />
                <p style={{ paddingLeft: '0.5rem' }}>Search By Key</p>
              </> : null}
            </S.ButtonBox>
            <S.SearchBox>
              <S.InputBox>
                <S.SearchIcon src={ic_search} />
                <S.SearchInput
                  onChange={handleSearchInput}
                  onKeyUp={checkTyping}
                  onKeyDown={clearTyping}
                  value={searchValue}
                  placeholder="search..."
                />
              </S.InputBox>
            </S.SearchBox>
            {searching ? (
              <S.SearchNote>Searching...</S.SearchNote>
            ) : (
              <p style={{ marginTop: '1rem' }} />
            )}
          </S.FilterBox>
        </S.Header>
        <S.ListBox id="scrollElement">
          <S.TextTypeBox admin={isEnglish}>
            {isEnglish && (
              <>
                <div>Image</div>
                <S.VerticalLine />
              </>
            )}
            <div>Original</div>
            {!isEnglish && (
              <>
                <S.VerticalLine />
                <div>Translated</div>
              </>
            )}
          </S.TextTypeBox>
          {(() => {
            if (isLoading) {
              return (
                <div>
                  <S.CircularLoading />
                  <S.Wait>Loading...</S.Wait>
                </div>
              );
            }
            if (translate.items.length === 0) {
              return (
                <div>
                  <S.Wait>{searchValue ? 'No Search Data' : 'No Data'}</S.Wait>
                </div>
              );
            }
            // TODO: remove this line after removing allTranlateData
            if (allTranlateData.length === 0) {
              return (
                <div>
                  <S.CircularLoading />
                  <S.Wait>Loading...</S.Wait>
                </div>
              );
            }
            return (
              translate.items.map(
                (expression: any) =>
                  expression.id !== undefined && (
                    <React.StrictMode key={expression.id}>
                      <S.TextBox
                        admin={isEnglish}
                        to={{
                          pathname: `/details/${expression.id}`,
                          state: {
                            data: expression,
                            items: allTranlateData,
                            lanId: lanId,
                            pageNumber: currentOffset,
                          },
                        }}
                      >
                        {expression.translation &&
                          !expression.translation.value &&
                          expression.translation.previous_value && (
                            <S.Label>original updated</S.Label>
                          )}
                        {expression.images !== undefined && isEnglish && (
                          <>
                            <S.Image image={expression.images[0] && expression.images[0].url}>
                              {expression.images[0] === undefined ? '-' : ''}
                            </S.Image>
                          </>
                        )}
                        <S.OriginalTextBox>
                          <S.Text>{expression.value}</S.Text>
                          <S.DescText>{expression.description}</S.DescText>
                        </S.OriginalTextBox>
                        <div
                          style={{
                            width: '3rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginLeft: '0.5rem',
                          }}
                        >
                          {expression.is_plural ? (
                            <S.PluralIcon>p</S.PluralIcon>
                          ) : (
                            <S.EmptyBox />
                          )}
                          {expression.translation &&
                            !expression.translation.value &&
                            expression.translation.machine_translation ? (
                            <img src={translation} alt="google_translate" width="20" />
                          ) : (
                            <S.EmptyBox />
                          )}
                        </div>
                        {expression && !isEnglish && expression.translation && (
                          <S.Text>
                            {expression.translation.value ??
                              expression.translation.machine_translation}
                          </S.Text>
                        )}
                      </S.TextBox>
                    </React.StrictMode>
                  )
              )
            )
          })()}
        </S.ListBox>
        <S.Pagination>
          <S.PaginationNumber>
            {realPageNumber + 1} / {numberOfPages}
          </S.PaginationNumber>
          <S.PaginationButton onClick={prev} enabled={!isFirst}>
            Prev
          </S.PaginationButton>
          <S.PaginationButton onClick={next} enabled={!isLast}>
            Next
          </S.PaginationButton>
        </S.Pagination>
      </S.ListContainer>
    </MainContainer>
  );
};

export default Main;
