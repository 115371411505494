import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { MainContainer } from '../../../assets/styles/commonStyle';
import * as S from './style';
import { checkAdmin } from '../../../containers/ExpressionContainer';
import { LANGUAGES_POST } from '../../../lib/api/auth_api';
const Language = () => {
  const [languageCode, setLanguageCode] = useState<string>();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<any>();
  const history = useHistory();

  useEffect(() => {
    checkAdmin(history);
    inputRef.current.focus();
  }, [history]);

  const addLanguage = async () => {
    if (!languageCode) {
      return;
    }
    setLoading(true);
    const response = await LANGUAGES_POST(languageCode).catch((err) => alert(err.message));
    if (!response) {
      setLoading(false);
      return;
    }
    history.push('/admin/progress');
  };

  const onChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target
    setLanguageCode(value);
  };

  const enter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      e.preventDefault();
      addLanguage();
    }
  };

  return (
    <MainContainer>
      <S.Back onClick={() => history.push('/admin/progress')} />
      <S.Form>
        <S.UploadMessage>{loading ? 'Adding a language...' : ''}</S.UploadMessage>
        <S.Explain>Please type a language code of the language want to add. You can find the code at <a href='https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes' target='_blank' rel="noreferrer">this link</a>.</S.Explain>
        <S.Textarea
          name="code"
          value={languageCode}
          onChange={onChange}
          placeholder="ISO 639-1 code (Required)"
          onKeyDown={enter}
          ref={inputRef}
        />
        <S.CreateButton onClick={addLanguage}>Add a language</S.CreateButton>
      </S.Form>
    </MainContainer>
  );
};

export default Language;
