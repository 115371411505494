import { EXPRESSIONS_POST } from '../lib/api/post_expression_api';

export const checkAdmin = (history: any) => {
  const user = localStorage.getItem('userId');
  if (!user) {
    history.push('/signin');
    return false;
  }
  if (typeof user === 'string') {
    const b = JSON.parse(user) as { is_admin: boolean };
    if (!b.is_admin) {
      history.push('/');
      return false;
    }
  }
  return true;
};

export const postExpression = (
  inputs: { text: string; desc: string },
  isPlural: boolean,
  setLoading: Function
) => {
  EXPRESSIONS_POST(inputs, isPlural)
    .then(() => {
      setLoading(false);
      alert('Created successfully.');
    })
    .catch((err) => {
      setLoading(false);
      alert(err.message);
    });
};
