import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { MainContainer } from '../../../assets/styles/commonStyle';
import * as S from './style';
import { checkAdmin, postExpression } from '../../../containers/ExpressionContainer';
const Expression = () => {
  const [inputs, setInputs] = useState({
    text: '',
    desc: '',
  });
  const [isPlural, setIsPlural] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<any>();
  const history = useHistory();

  useEffect(() => {
    checkAdmin(history);
    inputRef.current.focus();
  }, [history]);

  const postExpressionData = () => {
    if (!inputs.text) {
      return;
    }
    setLoading(true);
    postExpression(inputs, isPlural, setLoading);
    setInputs({ ...inputs, text: '', desc: '' });
    setIsPlural(false);
    inputRef.current.focus();
  };

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCheckbox = () => {
    setIsPlural(!isPlural);
  };

  const enter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      e.preventDefault();
      postExpressionData();
    }
  };

  return (
    <MainContainer>
      <S.Back onClick={() => history.push('/')} />
      <S.Form>
        <S.UploadMessage>{loading ? 'Creating an Expression...' : ''}</S.UploadMessage>
        <S.Explain>Please add a expression. Text is a required value.</S.Explain>
        <S.Textarea
          name="text"
          value={inputs.text}
          onChange={onChange}
          placeholder="text(required)"
          onKeyDown={enter}
          ref={inputRef}
        />
        <S.Textarea
          name="desc"
          value={inputs.desc}
          onChange={onChange}
          placeholder="description"
          onKeyDown={enter}
        />
        <div>
          <input
            type="checkbox"
            name="plural"
            defaultChecked={isPlural}
            onChange={handleCheckbox}
          />
          <label
            style={{
              marginLeft: '0.5rem',
              fontSize: '1.2rem',
            }}
            htmlFor="plural"
          >
            is plurals?
          </label>
        </div>
        <S.CreateButton onClick={postExpressionData}>create an expression</S.CreateButton>
      </S.Form>
    </MainContainer>
  );
};

export default Expression;
