import { endpoint } from './endpoint';

export const EXPRESSIONS_POST = async (inputs: { text: string; desc: string }, plural: boolean) => {
  const response = await fetch(`${endpoint}/expressions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      text: inputs.text,
      description: inputs.desc,
      is_plural: plural,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};
