import { endpoint } from './endpoint';

export const PROGRESS_GET = async () => {
  const response = await fetch(`${endpoint}/statistics/progress`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `${localStorage.getItem('accessToken')}`,
    },
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};
