import styled from 'styled-components';

type show = {
  data: boolean;
};

export const InstructionsContainer = styled.div<show>`
  display: ${(props) => (props.data ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  width: 90%;
  min-height: 1rem;
  margin: 1rem 0;
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.4rem;
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0.4rem;
  font-family: '';
`;

export const PluralTypeInfo = styled.div`
  padding: 0.4rem 1rem;
  box-sizing: border-box;
`;
