import { useState } from 'react';
import * as S from './style';

export const ButtonComponent = ({
  prevData,
  nextData,
  isFirst,
  isEnd,
  isDeleted,
  location,
  currentIdx,
  update,
  edited,
}: any) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <S.ButtonBox>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          marginBottom: '0.4rem',
        }}
      >
        <S.TextExplain explain={isShow}>
          Press
          {/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
            ? ' Command + Enter (⌘ ↩︎)'
            : ' Ctrl + Enter'}{' '}
        </S.TextExplain>
        <S.Button
          confirm
          onClick={update}
          onMouseEnter={() => setIsShow(true)}
          onMouseLeave={() => setIsShow(false)}
        >
          {isDeleted ? 'Restore' : edited ? 'Save' : 'Confirm'} {isEnd? 'and Go To Home' : 'and Go To Next'}
        </S.Button>
      </div>
      {isEnd ? (
        <S.SizedBox />
      ) : (
        <S.MoveButton
          to={{
            pathname: `/details/${nextData ? nextData.id : 1}`,
            state: {
              data: location.state.items[currentIdx + 1],
              items: location.state.items,
            },
          }}
        >
          Skip
        </S.MoveButton>
      )}
    </S.ButtonBox>
  );
};

export default ButtonComponent;
