import { endpoint } from './endpoint';

export const IMAGES_PUT = async (id: number, desc: string) => {
  const response = await fetch(`${endpoint}/images/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      id: id,
      description: desc,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};
