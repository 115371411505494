import styled from 'styled-components';
import signinBackground from '../../assets/images/signin_background.jpg';
import signupBackground from '../../assets/images/signup_background.jpg';
import ic_back from '../../assets/images/ic_back.png';

type auth = {
  page?: string;
  succes?: boolean;
};

export const ImageBackground = styled.div<auth>`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url(${(props) => (props.page === 'signin' ? signinBackground : signupBackground)})
    no-repeat center center;
  background-size: cover;
  filter: blur(0.2rem);
`;

export const Form = styled.div`
  width: 40%;
  height: 40%;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 10px;
  /* background-color: #f7e9e4; */
  opacity: 0.9;
`;

export const TitleBox = styled.div`
  flex-direction: flex-start;
`;

export const Title = styled.h2`
  text-shadow: 1px 1px 5px #fbb9ab;
`;

export const I18N = styled.h2`
  color: #e38174;
  text-shadow: 1px 1px 3px #fbb9ab;
`;

export const Center = styled.div`
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 90%;
`;

export const InputWrapper = styled.div`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 60%;
  height: 5rem;
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  height: 2rem;
  box-sizing: border-box;
  padding: 5px;
  background: none;
  border: none;
  border-bottom: solid 3px #fbb9ab;
  outline: none;
`;

export const ErrorText = styled.div<auth>`
  visibility: ${(props) => (props.succes ? 'hidden' : 'visible')};
  color: red;
`;

export const Select = styled.select`
  position: relative;
  color: #777;
  padding: 0.6em 1.4em 0.5em 0.8em;
  margin: 0;
  border: 2px solid #fbb9ab;
  border-radius: 0.5rem;
  cursor: pointer;
  :focus {
    outline: none;
  }
`;

export const Button = styled.button`
  width: 60%;
  height: 3rem;
  color: white;
  font-weight: bold;
  font-size: 1.3rem;
  background: rgb(164, 204, 241);
  background: linear-gradient(39deg, rgba(164, 204, 241, 1) 0%, rgba(251, 185, 171, 1) 100%);

  :hover {
    opacity: 0.8;
  }
`;

export const HaveAccount = styled.a`
  margin-top: 1rem;
  text-decoration: none;
  color: black;
  font-weight: bold;
  z-index: 1;
`;

export const Back = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  background: url(${ic_back}) no-repeat center center;
`;
