import { CHANGE_PASSWORD_PUT, SIGN_IN_POST, SIGN_UP_POST } from '../lib/api/auth_api';

export const changePasswordPut = (
  inputs: { newPassword: string; confirmPassword: string },
  setConfirmError: Function
) => {
  if (!inputs.newPassword || !inputs.confirmPassword) {
    alert('Please check the input box.');
    return;
  }

  if (inputs.newPassword !== inputs.confirmPassword) {
    alert('The passwords do not match.');
    setConfirmError(false);
    return;
  }

  CHANGE_PASSWORD_PUT(inputs.newPassword)
    .then(() => {
      alert('Password change succeeded');
      window.location.href = '/';
    })
    .catch((err) => {
      alert(err.message);
      setConfirmError(true);
    });
};

export const signInPost = (inputs: any, setEmailError: Function, setPasswordError: Function) => {
  signInInputCheck(inputs, setEmailError, setPasswordError);
  if (!inputs.email || !inputs.password) {
    alert('Please check the input box.');
    return;
  }

  SIGN_IN_POST(inputs)
    .then((res) => {
      localStorage.setItem('accessToken', res.access_token);
      localStorage.setItem('userId', JSON.stringify(res));
      localStorage.setItem('resentPage', JSON.stringify(0));
      window.location.href = '/';
    })
    .catch((err) => {
      alert(err.message);
      setEmailError(false);
    });
};

const signInInputCheck = (inputs: any, setEmailError: Function, setPasswordError: Function) => {
  setEmailError(!inputs.email ? false : true);
  setPasswordError(!inputs.password ? false : true);
};

export const signUpPost = (
  inputs: any,
  lanId: number,
  setNameError: Function,
  setEmailError: Function,
  setPasswordError: Function,
  setPasswordCheckError: Function
) => {
  signUpInputCheck(inputs, setNameError, setEmailError, setPasswordError, setPasswordCheckError);
  if (
    !(
      inputs.name &&
      inputs.email &&
      inputs.password &&
      inputs.password === inputs.checkPassword &&
      lanId !== 0
    )
  ) {
    alert('Please check the input box or language.');
    return;
  }

  SIGN_UP_POST(inputs, lanId)
    .then(() => {
      alert('Sign-up succeeded');
      window.location.href = '/';
    })
    .catch((err) => {
      alert(err.message);
    });
};

const signUpInputCheck = (
  inputs: any,
  setNameError: Function,
  setEmailError: Function,
  setPasswordError: Function,
  setPasswordCheckError: Function
) => {
  setNameError(!inputs.name ? false : true);
  setEmailError(!inputs.email ? false : true);
  setPasswordError(!inputs.password ? false : true);
  setPasswordCheckError(inputs.password !== inputs.checkPassword ? false : true);
};
