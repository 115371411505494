import { useState } from 'react';
import * as S from './instructionsStyles';

const Instructions = () => {
  const [showInfo, setShowInfo] = useState(false);
  const showData = () => {
    setShowInfo(!showInfo);
  };
  return (
    <>
      <button onClick={showData} style={{ marginTop: '1rem' }}>
        translation guide
      </button>
      <S.InstructionsContainer data={showInfo}>
        <strong>Remember when translating:</strong>
        <S.TextBox>
          <S.FlexBox>
            <p>1.&nbsp;&nbsp;&nbsp;</p>
            <p>Preserve the meaning but don't translate word to word.</p>
          </S.FlexBox>
          <S.FlexBox>
            <p>2.&nbsp;&nbsp;</p>
            <p>
              Know your audience (social, cultural profile of the people who speak the language) --
              This helps you to better communicate the screen's meaning.
            </p>
          </S.FlexBox>
          <S.FlexBox>
            <p>3.&nbsp;&nbsp;</p>
            <p>
              Depending on the nature of language you are translating into, choose simpler, shorter
              words when available.
            </p>
          </S.FlexBox>
          <S.FlexBox>
            <p>4.&nbsp;&nbsp;</p>
            <p>
              Try to use the same length of text if possible. This way, the UI won't have to be
              redesigned.
            </p>
          </S.FlexBox>
          <S.FlexBox>
            <p>5.&nbsp;&nbsp;</p>
            <strong>
              Some texts include "equations" in &#123;&#125;. They are not meant to be altered. Only
              alter them if you feel you really understand what it is trying to accomplish.
            </strong>
          </S.FlexBox>
          <S.FlexBox>
            <p>6.&nbsp;&nbsp;</p>
            <p>
              If you notice any ↩ characters in the text, they must be preserved. You can do this by
              hitting Enter/Return to force a new line to be created.
            </p>
          </S.FlexBox>
          <S.FlexBox>
            <p>7.&nbsp;&nbsp;</p>
            <p>There is a description of this text. Please review them.</p>
          </S.FlexBox>
        </S.TextBox>
      </S.InstructionsContainer>
    </>
  );
};

export default Instructions;
