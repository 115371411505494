import React from 'react'
import { MainContainer } from '../../assets/styles/commonStyle'
import { Block, CC } from './style'

const Color = () => {
  return (
    <MainContainer>
      <CC>main colors</CC>
      <Block a="#F3F0F2">#F3F0F2</Block>
      <Block a="#F7E9E4">#F7E9E4</Block>
      <Block a="#FBB9AB">#FBB9AB</Block>
      <Block a="#BAD1E6">#BAD1E6</Block>
      <Block a="#D4E4F2">#D4E4F2</Block>
      <Block a="#E7EDF7">#E7EDF7</Block>
    </MainContainer>
  )
}

export default Color
