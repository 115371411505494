import styled from 'styled-components';
import ic_back from '../../../assets/images/ic_back.png';
import adminExpression_background from '../../../assets/images/adminExpression_background.jpg';

export const Background = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url(${adminExpression_background}) no-repeat center center;
  background-size: cover;
`;

export const UploadMessage = styled.div`
  font-size: 2rem;
  font-weight: bold;
  height: 2rem;
  color: #ff866b;
`;

export const Explain = styled.p`
  font-size: 1.2rem;
`;

export const Back = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
  background: url(${ic_back}) no-repeat center center;
`;

export const Form = styled.div`
  width: 30%;
  height: 50%;
  flex-direction: column;
  justify-content: space-around;
`;

export const InputBox = styled.div`
  flex-direction: column;
`;

export const Textarea = styled.textarea`
  margin: 0.5rem;
  padding: 0.5rem;
  width: 30rem;
  height: 6rem;
  line-height: 1.5rem;
  font-size: 1.2rem;
  box-sizing: border-box;

  ::placeholder {
    font-size: 1.2rem;
  }
`;

export const Input = styled.input`
  /* opacity: 0.9; */
`;

export const ImageBox = styled.div`
  width: 100%;
  height: 10rem;
  overflow-x: scroll;
`;

export const Image = styled.img`
  height: 8rem;
  background-size: cover;
`;

export const CreateButton = styled.button`
  background-color: black;
  color: white;
  opacity: 0.9;
`;
