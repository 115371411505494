import { endpoint } from './endpoint';

export const LANGUAGES_GET = async () => {
  const response = await fetch(`${endpoint}/languages`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};

export const LANGUAGES_POST = async (languageCode: string) => {
  const response = await fetch(`${endpoint}/languages`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: `${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      code: languageCode,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message);
  }

  return (await response.json()) as {
    id: number,
    code: string,
    name: string,
    native_name: string,
    emoji: string,
    plural_types: string[]
  };
};

export const SIGN_UP_POST = async (inputs: any, lanId: number) => {
  const response = await fetch(`${endpoint}/users/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name: inputs.name,
      email: inputs.email,
      password: inputs.password,
      language_id: lanId,
      is_admin: false,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};

export const SIGN_IN_POST = async (inputs: { email: string; password: string }) => {
  const response = await fetch(`${endpoint}/users/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: inputs.email,
      password: inputs.password,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};

export const CHANGE_PASSWORD_PUT = async (password: string) => {
  const response = await fetch(`${endpoint}/users/password`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({
      password: password,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};

export const CHANGE_LANGUAGE_PUT = async (language_id: number) => {
  const response = await fetch(`${endpoint}/users/language`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json', 
      Authorization: `${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify({ language_id }),
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};