/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { TRANSLATIONS_PUT } from '../../../lib/api/put_translation_api';
import { ButtonComponent } from '../ButtonComponent';
import { pluralExplainData } from './pluralExplanations';
import * as S from './pluralStyle';
import * as D from '../style';
import { textAreaLineSet, textAreaValueSet, translationTypeSwitch } from './translationTypeSetting';

const Plurals = ({
  id,
  translation,
  prevData,
  nextData,
  isFirst,
  isEnd,
  history,
  location,
  checkEnd,
  currentIdx,
}: any) => {
  const [isEdited, setIsEdited] = useState(false);
  const [zeroDatas, setZeroDatas] = useState({
    type: 'zero',
    plural: false,
    translatedText: '',
    id: 0,
    previous_value: '',
    custom: '',
    translationType: 'none',
    isSeleted: true,
  });
  const [oneDatas, setOneDatas] = useState({
    type: 'one',
    plural: false,
    translatedText: '',
    id: 0,
    previous_value: '',
    custom: '',
    translationType: 'none',
    isSeleted: true,
  });
  const [twoDatas, setTwoDatas] = useState({
    type: 'two',
    plural: false,
    translatedText: '',
    id: 0,
    previous_value: '',
    custom: '',
    translationType: 'none',
    isSeleted: true,
  });
  const [fewDatas, setFewDatas] = useState({
    type: 'few',
    plural: false,
    translatedText: '',
    id: 0,
    previous_value: '',
    custom: '',
    translationType: 'none',
    isSeleted: true,
  });
  const [manyDatas, setManyDatas] = useState({
    type: 'many',
    plural: false,
    translatedText: '',
    id: 0,
    previous_value: '',
    custom: '',
    translationType: 'none',
    isSeleted: true,
  });
  const [otherDatas, setOtherDatas] = useState({
    type: 'other',
    plural: false,
    translatedText: '',
    id: 0,
    previous_value: '',
    custom: '',
    translationType: 'none',
    isSeleted: true,
  });
  const [allSeleted, setAllSeleted] = useState(false);

  useEffect(() => {
    if (translation && translation.previous_value && !translation.value) {
      setAllSeleted(
        zeroDatas.isSeleted &&
          oneDatas.isSeleted &&
          twoDatas.isSeleted &&
          fewDatas.isSeleted &&
          manyDatas.isSeleted &&
          otherDatas.isSeleted
      );
    } else {
      setAllSeleted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zeroDatas, oneDatas, twoDatas, fewDatas, manyDatas, otherDatas]);

  useEffect(() => {
    if (
      translation &&
      translation.previous_value &&
      !zeroDatas.previous_value &&
      !oneDatas.previous_value &&
      !twoDatas.previous_value &&
      !fewDatas.previous_value &&
      !manyDatas.previous_value &&
      !otherDatas.previous_value
    ) {
      setAllSeleted(true);
    }
  });

  useEffect(() => {
    if (translation) {
      let value = translation.value ?? translation.machine_translation;
      switch (zeroDatas.translationType) {
        case 'none' || 'translatedText':
          value = translation.value ?? translation.machine_translation;
          break;
        case 'previous_value':
          value = translation.previous_value;
          break;
        case 'custom':
          value =
            translation.machine_translation +
            '\n====================\n' +
            translation.previous_value;
          break;
        default:
          break;
      }
      if (zeroDatas.translatedText !== value) {
        setIsEdited(true);
      } else {
        setIsEdited(false);
      }
    }
  }, [zeroDatas.translatedText, zeroDatas.previous_value, zeroDatas.custom]);

  useEffect(() => {
    if (translation) {
      let value = translation.value ?? translation.machine_translation;
      switch (oneDatas.translationType) {
        case 'none' || 'translatedText':
          value = translation.value ?? translation.machine_translation;
          break;
        case 'previous_value':
          value = translation.previous_value;
          break;
        case 'custom':
          value =
            translation.machine_translation +
            '\n====================\n' +
            translation.previous_value;
          break;
        default:
          break;
      }
      if (oneDatas.translatedText !== value) {
        setIsEdited(true);
      } else {
        setIsEdited(false);
      }
    }
  }, [oneDatas.translatedText, oneDatas.previous_value, oneDatas.custom]);

  useEffect(() => {
    if (translation) {
      let value = translation.value ?? translation.machine_translation;
      switch (twoDatas.translationType) {
        case 'none' || 'translatedText':
          value = translation.value ?? translation.machine_translation;
          break;
        case 'previous_value':
          value = translation.previous_value;
          break;
        case 'custom':
          value =
            translation.machine_translation +
            '\n====================\n' +
            translation.previous_value;
          break;
        default:
          break;
      }
      if (twoDatas.translatedText !== value) {
        setIsEdited(true);
      } else {
        setIsEdited(false);
      }
    }
  }, [twoDatas.translatedText, twoDatas.previous_value, twoDatas.custom]);

  useEffect(() => {
    if (translation) {
      let value = translation.value ?? translation.machine_translation;
      switch (fewDatas.translationType) {
        case 'none' || 'translatedText':
          value = translation.value ?? translation.machine_translation;
          break;
        case 'previous_value':
          value = translation.previous_value;
          break;
        case 'custom':
          value =
            translation.machine_translation +
            '\n====================\n' +
            translation.previous_value;
          break;
        default:
          break;
      }
      if (fewDatas.translatedText !== value) {
        setIsEdited(true);
      } else {
        setIsEdited(false);
      }
    }
  }, [fewDatas.translatedText, fewDatas.previous_value, fewDatas.custom]);

  useEffect(() => {
    if (translation) {
      let value = translation.value ?? translation.machine_translation;
      switch (manyDatas.translationType) {
        case 'none' || 'translatedText':
          value = translation.value ?? translation.machine_translation;
          break;
        case 'previous_value':
          value = translation.previous_value;
          break;
        case 'custom':
          value =
            translation.machine_translation +
            '\n====================\n' +
            translation.previous_value;
          break;
        default:
          break;
      }
      if (manyDatas.translatedText !== value) {
        setIsEdited(true);
      } else {
        setIsEdited(false);
      }
    }
  }, [manyDatas.translatedText, manyDatas.previous_value, manyDatas.custom]);

  useEffect(() => {
    if (translation) {
      let value = translation.value ?? translation.machine_translation;
      switch (otherDatas.translationType) {
        case 'none' || 'translatedText':
          value = translation.value ?? translation.machine_translation;
          break;
        case 'previous_value':
          value = translation.previous_value;
          break;
        case 'custom':
          value =
            translation.machine_translation +
            '\n====================\n' +
            translation.previous_value;
          break;
        default:
          break;
      }
      if (otherDatas.translatedText !== value) {
        setIsEdited(true);
      } else {
        setIsEdited(false);
      }
    }
  }, [otherDatas.translatedText, otherDatas.previous_value, otherDatas.custom]);

  useEffect(() => {
    if (!translation) {
      return;
    }

    const existPlurals = translation.plurals.map((e: any) => {
      return {
        id: e.id,
        type: e.type,
        value: e.value,
        previous_value: e.previous_value,
        custom: e.machine_translation + '\n====================\n' + e.previous_value,
        machine_translation: e.machine_translation,
      };
    });

    for (let item of existPlurals) {
      if (item.type === 'zero') {
        setZeroDatas({
          ...zeroDatas,
          plural: true,
          translatedText: item.value ?? item.machine_translation,
          id: item.id,
          previous_value: item.previous_value,
          custom: item.custom,
          isSeleted: false,
        });
      }
      if (item.type === 'one') {
        setOneDatas({
          ...oneDatas,
          plural: true,
          translatedText: item.value ?? item.machine_translation,
          id: item.id,
          previous_value: item.previous_value,
          custom: item.custom,
          isSeleted: false,
        });
      }
      if (item.type === 'two') {
        setTwoDatas({
          ...twoDatas,
          plural: true,
          translatedText: item.value ?? item.machine_translation,
          id: item.id,
          previous_value: item.previous_value,
          custom: item.custom,
          isSeleted: false,
        });
      }
      if (item.type === 'few') {
        setFewDatas({
          ...fewDatas,
          plural: true,
          translatedText: item.value ?? item.machine_translation,
          id: item.id,
          previous_value: item.previous_value,
          custom: item.custom,
          isSeleted: false,
        });
      }
      if (item.type === 'many') {
        setManyDatas({
          ...manyDatas,
          plural: true,
          translatedText: item.value ?? item.machine_translation,
          id: item.id,
          previous_value: item.previous_value,
          custom: item.custom,
          isSeleted: false,
        });
      }
      if (item.type === 'other') {
        setOtherDatas({
          ...otherDatas,
          plural: true,
          translatedText: item.value ?? item.machine_translation,
          id: item.id,
          previous_value: item.previous_value,
          custom: item.custom,
          isSeleted: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translation]);

  const handleZeroInput = (e: any) => {
    const { name, value } = e.target;

    setZeroDatas({
      ...zeroDatas,
      [name]: value,
    });
  };
  const handleOneInput = (e: any) => {
    const { name, value } = e.target;

    setOneDatas({
      ...oneDatas,
      [name]: value,
    });
  };
  const handleTwoInput = (e: any) => {
    const { name, value } = e.target;

    setTwoDatas({
      ...twoDatas,
      [name]: value,
    });
  };
  const handleFewInput = (e: any) => {
    const { name, value } = e.target;

    setFewDatas({
      ...fewDatas,
      [name]: value,
    });
  };
  const handleManyInput = (e: any) => {
    const { name, value } = e.target;

    setManyDatas({
      ...manyDatas,
      [name]: value,
    });
  };
  const handleOtherInput = (e: any) => {
    const { name, value } = e.target;

    setOtherDatas({
      ...otherDatas,
      [name]: value,
    });
  };

  const pluralTextModification = () => {
    if (allSeleted) {
      TRANSLATIONS_PUT('', translation, [
        zeroDatas,
        oneDatas,
        twoDatas,
        fewDatas,
        manyDatas,
        otherDatas,
      ])
        .then(() => {
          if (!checkEnd) {
            history.push({
              pathname: `/details/${nextData ? nextData.id : 1}`,
              state: {
                data: location.state.items[currentIdx + 1],
                items: location.state.items,
              },
            });
          }
        })
        .catch((err) => alert(err.message));
    } else {
      alert('Please choose all type.');
    }
  };

  const enter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      pluralTextModification();
    }
  };

  const currentPluralData = (datas: { type: string }) => {
    return translation.plurals[
      translation.plurals.findIndex((i: { type: string }) => i.type === datas.type)
    ];
  };

  const otherOnly = () => {
    if (
      !zeroDatas.plural &&
      !oneDatas.plural &&
      !twoDatas.plural &&
      !fewDatas.plural &&
      !manyDatas.plural
    ) {
      return '';
    } else if (
      !zeroDatas.plural &&
      oneDatas.plural &&
      !twoDatas.plural &&
      !fewDatas.plural &&
      !manyDatas.plural &&
      otherDatas.plural
    ) {
      return 'plural';
    } else {
      return 'plural(other)';
    }
  };

  const otherPluralInfo = () => {
    if (
      !zeroDatas.plural &&
      !oneDatas.plural &&
      !twoDatas.plural &&
      !fewDatas.plural &&
      !manyDatas.plural
    ) {
      return <div />;
    } else {
      return (
        isPluralDataOverThree() && (
          <S.PluralTypeInfo plural={otherDatas.plural}>
            other : {pluralExplainData.other}
          </S.PluralTypeInfo>
        )
      );
    }
  };

  const isPluralDataOverThree = () => {
    let pluralArr = [
      zeroDatas.plural,
      oneDatas.plural,
      twoDatas.plural,
      fewDatas.plural,
      manyDatas.plural,
      otherDatas.plural,
    ];

    let trueCount = pluralArr.reduce((cnt: any, element) => cnt + (true === element), 0);
    if (trueCount >= 3) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.StrictMode>
      <S.PluralDataBox plural={zeroDatas.plural}>
        <S.PluralType>zero</S.PluralType>
      </S.PluralDataBox>
      {zeroDatas.previous_value && !translation.value && (
        <D.TypeSwitchBox>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, zeroDatas, currentPluralData(zeroDatas), setZeroDatas);
            }}
            value="translatedText"
          >
            Copy from Machine Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, zeroDatas, currentPluralData(zeroDatas), setZeroDatas);
            }}
            value="previous_value"
          >
            Copy from Previous Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, zeroDatas, currentPluralData(zeroDatas), setZeroDatas);
            }}
            value="custom"
          >
            Copy Both
          </D.TypeButton>
        </D.TypeSwitchBox>
      )}
      <D.TranlsationTextAreaBox>
        <S.PluralTranslate
          name={
            translation && zeroDatas.previous_value && !translation.value
              ? zeroDatas.translationType
              : 'translatedText'
          }
          auto={textAreaLineSet(zeroDatas, zeroDatas.translationType)}
          value={textAreaValueSet(zeroDatas, translation && translation.value)}
          onChange={handleZeroInput}
          plural={zeroDatas.plural}
          onKeyDown={enter}
          readOnly={
            zeroDatas.translationType === 'none' &&
            translation &&
            translation.previous_value &&
            !translation.value &&
            zeroDatas.previous_value
              ? true
              : false
          }
        />
      </D.TranlsationTextAreaBox>
      {isPluralDataOverThree() && (
        <S.PluralTypeInfo plural={zeroDatas.plural}>
          zero : {pluralExplainData.zero}
        </S.PluralTypeInfo>
      )}
      <S.PluralDataBox plural={oneDatas.plural}>
        <S.PluralType>singular</S.PluralType>
      </S.PluralDataBox>
      {oneDatas.previous_value && !translation.value && (
        <D.TypeSwitchBox>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, oneDatas, currentPluralData(oneDatas), setOneDatas);
            }}
            value="translatedText"
          >
            Copy from Machine Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, oneDatas, currentPluralData(oneDatas), setOneDatas);
            }}
            value="previous_value"
          >
            Copy from Previous Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, oneDatas, currentPluralData(oneDatas), setOneDatas);
            }}
            value="custom"
          >
            Copy Both
          </D.TypeButton>
        </D.TypeSwitchBox>
      )}
      <D.TranlsationTextAreaBox>
        <S.PluralTranslate
          name={
            translation && oneDatas.previous_value && !translation.value
              ? oneDatas.translationType
              : 'translatedText'
          }
          auto={textAreaLineSet(oneDatas, oneDatas.translationType)}
          value={textAreaValueSet(oneDatas, translation && translation.value)}
          onChange={handleOneInput}
          plural={oneDatas.plural}
          onKeyDown={enter}
          readOnly={
            translation &&
            oneDatas.translationType === 'none' &&
            translation.previous_value &&
            !translation.value &&
            oneDatas.previous_value
              ? true
              : false
          }
        />
      </D.TranlsationTextAreaBox>
      {isPluralDataOverThree() && (
        <S.PluralTypeInfo plural={oneDatas.plural}>one : {pluralExplainData.one}</S.PluralTypeInfo>
      )}
      <S.PluralDataBox plural={twoDatas.plural}>
        <S.PluralType>plural(two)</S.PluralType>
      </S.PluralDataBox>
      {twoDatas.previous_value && !translation.value && (
        <D.TypeSwitchBox>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, twoDatas, currentPluralData(twoDatas), setTwoDatas);
            }}
            value="translatedText"
          >
            Copy from Machine Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, twoDatas, currentPluralData(twoDatas), setTwoDatas);
            }}
            value="previous_value"
          >
            Copy from Previous Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, twoDatas, currentPluralData(twoDatas), setTwoDatas);
            }}
            value="custom"
          >
            Copy Both
          </D.TypeButton>
        </D.TypeSwitchBox>
      )}
      <D.TranlsationTextAreaBox>
        <S.PluralTranslate
          name={
            translation && twoDatas.previous_value && !translation.value
              ? twoDatas.translationType
              : 'translatedText'
          }
          auto={textAreaLineSet(twoDatas, twoDatas.translationType)}
          value={textAreaValueSet(twoDatas, translation && translation.value)}
          onChange={handleTwoInput}
          plural={twoDatas.plural}
          onKeyDown={enter}
          readOnly={
            translation &&
            twoDatas.translationType === 'none' &&
            translation.previous_value &&
            !translation.value &&
            twoDatas.previous_value
              ? true
              : false
          }
        />
      </D.TranlsationTextAreaBox>
      {isPluralDataOverThree() && (
        <S.PluralTypeInfo plural={twoDatas.plural}>two : {pluralExplainData.two}</S.PluralTypeInfo>
      )}
      <S.PluralDataBox plural={fewDatas.plural}>
        <S.PluralType>plural(few)</S.PluralType>
      </S.PluralDataBox>
      {fewDatas.previous_value && !translation.value && (
        <D.TypeSwitchBox>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, fewDatas, currentPluralData(fewDatas), setFewDatas);
            }}
            value="translatedText"
          >
            Copy from Machine Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, fewDatas, currentPluralData(fewDatas), setFewDatas);
            }}
            value="previous_value"
          >
            Copy from Previous Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, fewDatas, currentPluralData(fewDatas), setFewDatas);
            }}
            value="custom"
          >
            Copy Both
          </D.TypeButton>
        </D.TypeSwitchBox>
      )}
      <D.TranlsationTextAreaBox>
        <S.PluralTranslate
          name={
            translation && fewDatas.previous_value && !translation.value
              ? fewDatas.translationType
              : 'translatedText'
          }
          auto={textAreaLineSet(fewDatas, fewDatas.translationType)}
          value={textAreaValueSet(fewDatas, translation && translation.value)}
          onChange={handleFewInput}
          plural={fewDatas.plural}
          onKeyDown={enter}
          readOnly={
            translation &&
            fewDatas.translationType === 'none' &&
            translation.previous_value &&
            !translation.value &&
            fewDatas.previous_value
              ? true
              : false
          }
        />
      </D.TranlsationTextAreaBox>
      {isPluralDataOverThree() && (
        <S.PluralTypeInfo plural={fewDatas.plural}>few : {pluralExplainData.few}</S.PluralTypeInfo>
      )}
      <S.PluralDataBox plural={manyDatas.plural}>
        <S.PluralType>plural(many)</S.PluralType>
      </S.PluralDataBox>
      {manyDatas.previous_value && !translation.value && (
        <D.TypeSwitchBox>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, manyDatas, currentPluralData(manyDatas), setManyDatas);
            }}
            value="translatedText"
          >
            Copy from Machine Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, manyDatas, currentPluralData(manyDatas), setManyDatas);
            }}
            value="previous_value"
          >
            Copy from Previous Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, manyDatas, currentPluralData(manyDatas), setManyDatas);
            }}
            value="custom"
          >
            Copy Both
          </D.TypeButton>
        </D.TypeSwitchBox>
      )}
      <D.TranlsationTextAreaBox>
        <S.PluralTranslate
          name={
            translation && manyDatas.previous_value && !translation.value
              ? manyDatas.translationType
              : 'translatedText'
          }
          auto={textAreaLineSet(manyDatas, manyDatas.translationType)}
          value={textAreaValueSet(manyDatas, translation && translation.value)}
          onChange={handleManyInput}
          plural={manyDatas.plural}
          onKeyDown={enter}
          readOnly={
            translation &&
            manyDatas.translationType === 'none' &&
            translation.previous_value &&
            !translation.value &&
            manyDatas.previous_value
              ? true
              : false
          }
        />
      </D.TranlsationTextAreaBox>
      {isPluralDataOverThree() && (
        <S.PluralTypeInfo plural={manyDatas.plural}>
          many : {pluralExplainData.many}
        </S.PluralTypeInfo>
      )}
      <S.PluralDataBox plural={otherDatas.plural}>
        <S.PluralType>{otherOnly()}</S.PluralType>
      </S.PluralDataBox>
      {otherDatas.previous_value && !translation.value && (
        <D.TypeSwitchBox>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, otherDatas, currentPluralData(otherDatas), setOtherDatas);
            }}
            value="translatedText"
          >
            Copy from Machine Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, otherDatas, currentPluralData(otherDatas), setOtherDatas);
            }}
            value="previous_value"
          >
            Copy from Previous Translation
          </D.TypeButton>
          <D.TypeButton
            onClick={(e: any) => {
              translationTypeSwitch(e, otherDatas, currentPluralData(otherDatas), setOtherDatas);
            }}
            value="custom"
          >
            Copy Both
          </D.TypeButton>
        </D.TypeSwitchBox>
      )}
      <D.TranlsationTextAreaBox>
        <S.PluralTranslate
          name={
            translation && otherDatas.previous_value && !translation.value
              ? otherDatas.translationType
              : 'translatedText'
          }
          auto={textAreaLineSet(otherDatas, otherDatas.translationType)}
          value={textAreaValueSet(otherDatas, translation && translation.value)}
          onChange={handleOtherInput}
          plural={otherDatas.plural}
          onKeyDown={enter}
          readOnly={
            otherDatas.translationType === 'none' &&
            translation &&
            translation.previous_value &&
            !translation.value &&
            otherDatas.previous_value
              ? true
              : false
          }
        />
      </D.TranlsationTextAreaBox>
      {otherPluralInfo()}
      <ButtonComponent
        admin={false}
        prevData={prevData}
        nextData={nextData}
        isFirst={isFirst}
        isEnd={isEnd}
        location={location}
        currentIdx={currentIdx}
        update={pluralTextModification}
        edited={isEdited}
      />
    </React.StrictMode>
  );
};

export default Plurals;
