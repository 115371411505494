/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useEffect } from 'react';
import Plurals from './plurals/Plurals';
import * as S from './style';
import {
  checkAdmin,
  checkPrevNextButton,
  deleteImage,
  getIndex,
  imageUpload,
  restoreExpression,
  originalTextModification,
  textModification,
} from '../../containers/DetailContainer';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { ButtonComponent } from './ButtonComponent';
import ImagePopup from './ImagePopup';
import Instructions from './Instructions';
import { Language } from '../../models/language';

const Details = ({ location, history }: any) => {
  const { id, value, description, images, is_plural, is_deleted = false, singular, translation, key } = location.state.data;
  const [revisedData, setRevisedData] = useState<any>();
  const [revisedImage, setRevisedImage] = useState<any>();
  const [textAreaValue, setTextAreaValue] = useState({
    originalText: value,
    singularText: singular,
    descriptionText: description,
    translatedText: translation ? translation.value ?? translation.machine_translation : '',
    previousText: translation ? translation.previous_value ?? '' : '',
    customText: translation
      ? translation.machine_translation + '\n====================\n' + translation.previous_value
      : '',
  });
  const [isEdited, setIsEdited] = useState(false);
  const [translationType, setTranslationType] = useState('none');
  const [isSeleted, setIsSeleted] = useState(
    translation && translation.previous_value && !translation.value ? false : true
  );
  const [imageId, setImageId] = useState(images ? images[0]?.id : 0);
  const [gallery, setGallery] = useState(images);
  const [descInputs, setDescInputs] = useState({
    newImageDesc: '',
    updateImageDesc: '',
  });
  const [isFirst, setIsFirst] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [prevData, setPrevData] = useState<any>(null);
  const [nextData, setNextData] = useState<any>(null);
  const [isAdmin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [showKey, setShowKey] = useState(false);

  const getUserLanguage = () => {
    const userData = localStorage.getItem('userId');
    if (userData) {
      return JSON.parse(userData).language as Language;
    }
  };
  const language = getUserLanguage();
  const isEnglish = language?.code === 'en'; // user must be admin

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  let currentIdx = location.state.items.findIndex((i: { id: number }) => i.id === id);
  useEffect(() => {
    getIndex(setIsEnd, setNextData, setIsFirst, setPrevData, location, currentIdx);
    checkPrevNextButton(currentIdx, setIsEnd, setIsFirst, location);
    checkAdmin(setAdmin);

    const resentPage = currentIdx !== 0 ? Math.ceil(currentIdx / 15) - 1 : 0;
    localStorage.setItem('resentPage', JSON.stringify(resentPage));

    setTextAreaValue({
      ...textAreaValue,
      originalText: value,
      singularText: singular,
      descriptionText: description,
      translatedText: translation ? translation.value ?? translation.machine_translation : '',
      previousText: translation ? translation.previous_value ?? '' : '',
      customText: translation
        ? translation.machine_translation + '\n====================\n' + translation.previous_value
        : '',
    });
    setTranslationType('none');
    setIsSeleted(translation && translation.previous_value && !translation.value ? false : true);
    setImageId(images ? images[0]?.id : 0);
    setGallery(images);
    setShowKey(false);
  }, [currentIdx, images, location, translation]);

  useEffect(() => {
    if (revisedData === undefined) return;
    location.state.items[currentIdx].translation = revisedData;

    if (!isEnd) {
      history.push({
        pathname: `/details/${nextData ? nextData.id : 1}`,
        state: {
          data: location.state.items[currentIdx + 1],
          items: location.state.items,
        },
      });
    }
  }, [revisedData]);

  useEffect(() => {
    if (revisedImage === undefined) return;

    const imageIndex = location.state.items[currentIdx].images.findIndex(
      (e: any) => e.id === revisedImage.id
    );

    if (imageIndex === -1) {
      location.state.items[currentIdx].images.push(revisedImage);
    } else {
      location.state.items[currentIdx].images[imageIndex] = revisedImage;
    }

    setGallery(location.state.items[currentIdx].images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revisedImage]);

  useEffect(() => {
    setIsEdited(
      textAreaValue.originalText !== value || 
      textAreaValue.singularText !== singular
    );
  }, [textAreaValue.originalText, textAreaValue.singularText]);

  useEffect(() => {
    if (translation) {
      setIsEdited(
        textAreaValue.translatedText !== (translation.value ?? translation.machine_translation) ||
        textAreaValue.previousText !== (translation.previous_value ?? '') ||
        textAreaValue.customText !== (translation.machine_translation + '\n====================\n' + translation.previous_value)
      );
    }
  }, [textAreaValue.translatedText, textAreaValue.previousText, textAreaValue.customText]);

  const deleteImagesItem = (id: number) => {
    const imageIndex = location.state.items[currentIdx].images.findIndex(
      (i: { id: number }) => i.id === id
    );

    if (imageIndex === -1) return;

    location.state.items[currentIdx].images.splice(imageIndex, 1);
    deleteImage(id);
    setGallery(location.state.items[currentIdx].images);
  };

  const changeTextAreaValue = (e: any) => {
    const { name, value } = e.target;
    setTextAreaValue({ ...textAreaValue, [name]: value });
  };

  const onChangeDesc = (e: any) => {
    const { name, value } = e.target;
    setDescInputs({ ...descInputs, [name]: value });
  };

  const imageView = (e: any) => {
    setImageId(Number(e.target.id));
  };

  const previewImage = files.map((file: any) => (
    <div key={file.name}>
      <img
        src={file.preview}
        style={{ height: '50px', boxSizing: 'border-box' }}
        alt="preview"
      ></img>
    </div>
  ));

  const emptyBox = () => {
    return (
      <div
        style={{
          height: '50px',
          width: '100px',
          border: 'dotted 2px #ffb9ab',
          boxSizing: 'border-box',
        }}
      >
        Image
        <br />
        Preview
      </div>
    );
  };

  const enter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      e.preventDefault();
      if (isEnglish) {
        updateOriginal();
      } else {
        updateExpression();
      }
    }
  };

  const updateExpression = () => {
    if (isSeleted) {
      textModification(
        translationType === 'translatedText' || translationType === 'none'
          ? textAreaValue.translatedText
          : translationType === 'previousText'
            ? textAreaValue.previousText
            : textAreaValue.customText,
        translation,
        setRevisedData
      );
    } else {
      alert('Please choose the sentence type.');
    }
  };


  const updateOriginal = () => {
    function movePage () {
      if (isEnd) {
        history.push('/');
        return;
      }
      history.push({
        pathname: `/details/${nextData ? nextData.id : 1}`,
        state: {
          data: location.state.items[currentIdx + 1],
          items: location.state.items,
        },
      });
    };

    if (is_deleted) {
      restoreExpression(id).then(movePage).catch(alert);
      return;
    }
    const textValue = textAreaValue.originalText !== value ? textAreaValue.originalText : undefined;
    const descValue =
      textAreaValue.descriptionText !== description ? textAreaValue.descriptionText : undefined;
    const singularValue =
      textAreaValue.singularText !== singular ? textAreaValue.singularText : undefined;

    originalTextModification(id, undefined, textValue, descValue, singularValue).then(movePage).catch(alert);
  };

  const changeTranslationType = (e: any) => {
    let type = e.target.value;
    setIsSeleted(true);

    switch (translationType) {
      case 'translatedText':
        setTextAreaValue({ ...textAreaValue, translatedText: translation.machine_translation });
        setTranslationType(type);

        return;
      case 'previousText':
        setTextAreaValue({ ...textAreaValue, previousText: translation.previous_value });
        setTranslationType(type);

        return;
      case 'customText':
        setTextAreaValue({
          ...textAreaValue,
          customText:
            translation.machine_translation +
            '\n====================\n' +
            translation.previous_value,
        });
        setTranslationType(type);

        return;
      default:
        setTranslationType(type);
    }
  };

  const textAreaValueSet = () => {
    if (translationType === 'none' && textAreaValue.previousText && !translation.value) {
      return '';
    } else if (translationType === 'translatedText') {
      return textAreaValue.translatedText;
    } else if (translationType === 'previousText') {
      return textAreaValue.previousText;
    } else if (translationType === 'customText') {
      return textAreaValue.customText;
    } else {
      return textAreaValue.translatedText;
    }
  };

  const textAreaLineSet = () => {
    if (translationType === 'translatedText') {
      return textAreaValue.translatedText.split('\n').length + 2;
    } else if (translationType === 'previousText') {
      return textAreaValue.previousText.split('\n').length + 2;
    } else if (translationType === 'customText') {
      return textAreaValue.customText.split('\n').length + 2;
    } else {
      return textAreaValue.translatedText.split('\n').length + 2;
    }
  };

  return (
    <S.DetailPageContainer>
      <Link to="/">
        <S.Back />
      </Link>
      <S.DataContainer>
        {isAdmin ? (
          <S.AdminImage>
            <div>{files.length ? previewImage : emptyBox()}</div>
            <S.DropZone {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drop file or click here</p>
            </S.DropZone>
            <input
              name="newImageDesc"
              type="text"
              onChange={onChangeDesc}
              value={descInputs.newImageDesc}
              placeholder="image description"
            />
            {loading ? (
              <p>Wait...</p>
            ) : (
              <button
                onClick={() => {
                  imageUpload(setLoading, setRevisedImage, files[0], id, descInputs.newImageDesc);
                }}
              >
                Add Image
              </button>
            )}
          </S.AdminImage>
        ) : (
          <React.StrictMode />
        )}
        {gallery.length !== 0 ? (
          <S.ImageSet>
            <ImagePopup
              imageDatas={gallery}
              id={imageId}
              editable={isAdmin}
              setRevisedImage={setRevisedImage}
              deleteImage={deleteImagesItem}
            />
            <S.Gallery>
              {gallery &&
                gallery.map((e: any) => (
                  <S.Thumbnail
                    key={e.id}
                    image={e.url}
                    onClick={imageView}
                    id={e.id}
                    slot={e.url}
                    lang={e.description}
                  />
                ))}
            </S.Gallery>
          </S.ImageSet>
        ) : (
          <div />
        )}
        {!isEnglish && <Instructions />}
        <S.TextBox>
          <S.TextExplain explain={true}>Original in English</S.TextExplain>
          {
            isEnglish && is_plural ? <>
              <S.TextExplain explain={true}>singular</S.TextExplain>
              <S.TextArea
                auto={textAreaValue.singularText.split('\n').length + 2}
                name="singularText"
                disabled={is_deleted}
                value={textAreaValue.singularText}
                onChange={changeTextAreaValue}
                onKeyDown={enter}
              >
                {singular}
              </S.TextArea>
              <S.TextExplain explain={true}>plural</S.TextExplain>
            </> : null
          }
          <S.TextArea
            auto={textAreaValue.originalText.split('\n').length + 2}
            name="originalText"
            disabled={is_deleted}
            value={
              isEnglish ? textAreaValue.originalText : textAreaValue.originalText.replace(/\n/gi, '↩\n')
            }
            onChange={changeTextAreaValue}
            readOnly={!isEnglish}
            onKeyDown={enter}
          >
            {value}
          </S.TextArea>
          <S.TextExplain explain={true}>Description</S.TextExplain>
          <S.TextArea
            auto={textAreaValue.descriptionText.split('\n').length + 2}
            name="descriptionText"
            disabled={is_deleted}
            value={description || isEnglish ? textAreaValue.descriptionText : 'No description.'}
            onChange={changeTextAreaValue}
            readOnly={!isEnglish}
            onKeyDown={enter}
            placeholder="Add description..."
          >
            {textAreaValue.descriptionText}
          </S.TextArea>
          {isEnglish ?  (
            <ButtonComponent
              prevData={prevData}
              nextData={nextData}
              isFirst={isFirst}
              isEnd={isEnd}
              isDeleted={is_deleted}
              location={location}
              currentIdx={currentIdx}
              update={updateOriginal}
              edited={isEdited}
            />
          ) : (
            <>
              <S.TextExplain explain={true}>
                Please enter a translation in {language?.native_name}
              </S.TextExplain>
              {textAreaValue.previousText && !translation.value && !is_plural && (
                <S.TypeSwitchBox>
                  <S.TypeButton onClick={changeTranslationType} value="translatedText">
                    Copy from Machine Translation
                  </S.TypeButton>
                  <S.TypeButton onClick={changeTranslationType} value="previousText">
                    Copy from Previous Translation
                  </S.TypeButton>
                  <S.TypeButton onClick={changeTranslationType} value="customText">
                    Copy Both
                  </S.TypeButton>
                </S.TypeSwitchBox>
              )}
              {!is_plural ? (
                <React.StrictMode>
                  <S.TranlsationTextAreaBox>
                    <S.TextArea
                      auto={textAreaLineSet()}
                      name={
                        translation && translation.previous_value && !translation.value
                          ? translationType
                          : 'translatedText'
                      }
                      value={textAreaValueSet()}
                      onChange={changeTextAreaValue}
                      onKeyDown={enter}
                      readOnly={
                        translation &&
                          translationType === 'none' &&
                          translation.previous_value &&
                          !translation.value
                          ? true
                          : false
                      }
                    />
                  </S.TranlsationTextAreaBox>
                  <ButtonComponent
                    prevData={prevData}
                    nextData={nextData}
                    isFirst={isFirst}
                    isEnd={isEnd}
                    location={location}
                    currentIdx={currentIdx}
                    update={updateExpression}
                    edited={isEdited}
                  />
                </React.StrictMode>
              ) : (
                <React.StrictMode>
                  <Plurals
                    id={id}
                    translation={translation}
                    prevData={prevData}
                    nextData={nextData}
                    isFirst={isFirst}
                    isEnd={isEnd}
                    history={history}
                    location={location}
                    checkEnd={isEnd}
                    currentIdx={currentIdx}
                  />
                </React.StrictMode>
              )}
            </>
          )}
        </S.TextBox>
        <div style={{ marginTop: '1rem' }} />
        <div style={{ position: 'absolute', bottom: '0.5rem', left: '0', cursor: 'pointer' }}>
          <p
            onClick={() => setShowKey(!showKey)}
            style={{ backgroundColor: '#ddd', color: '#444', padding: '0.2rem' }}
          >
            key
          </p>
          <div
            style={{ display: showKey ? 'inline' : 'none', color: '#666', marginLeft: '0.5rem' }}
          >
            {key}
          </div>
        </div>
      </S.DataContainer>
    </S.DetailPageContainer>
  );
};

export default Details;
