import { endpoint } from './endpoint';

type Params ={
  id: number,
  key?: string,
  text?: string,
  description?: string,
  singular?: string,
  deleted?: boolean,
}

export const PUT_EXPRESSION_API = async ({id, key, text, description, singular, deleted}: Params
) => {
  let bodyData: any = { is_confirmed: true };

  if (key) {
    bodyData = { ...bodyData, key: key, };
  }
  if (text) {
    bodyData = { ...bodyData, text: text, };
  }
  if (description) {
    bodyData = { ...bodyData, description: description, };
  }
  if (singular) {
    bodyData = { ...bodyData, singular: singular, };
  }
  if (deleted !== undefined) {
    bodyData = { ...bodyData, is_deleted: deleted};
  }


  const response = await fetch(`${endpoint}/expressions/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(bodyData),
  });

  if (!response.ok) {
    const error = await response.json();
    const message = `An error has occured ${response.status}: ${error.message}`;
    throw new Error(message);
  }

  return await response.json();
};
